.route-sec .RaDatagrid-expandedPanel {
    background: #fff;
}

.route-toolbar{
    margin-right: 4px;
}
.route-toolbar .top-tlcount{
    position: relative;
    right: 240% !important;
}

.route-toolbar button.create-btn{
    border-radius: 6px !important;
    color: #FFFFFF;
    font-size: 13px;
    height: 38px;
    bottom: 12px;
  }
.route-toolbar button.create-btn svg{
    width: 1rem !important;
    height: 1rem !important;
  }
.route-toolbar button.create-btn span.MuiButton-startIcon{
    margin-right: 4px !important;
    display: flex !important;
    align-items: center;
 }

.route-sec .RaDatagrid-expandIcon{
    transition: none !important;
    transform: none !important;
}
.route-sec .RaDatagrid-expandIcon svg{
    fill: #4646F2;
}
.route-sec .RaDatagrid-expandIcon:hover,.route-sec .RaDatagrid-expandIcon:focus{
    background-color: transparent !important;
    border-radius: 0 !important;
    color: transparent !important;
    transform: none !important;
    transition: none !important;
}
.route-sec .RaDatagrid-expandIcon[aria-label="Expand"] svg path {
    d: path('M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z') !important;
}
.route-sec .RaDatagrid-expandIcon[aria-label="Close"] svg path {
    d: path('M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z') !important;
}
.route-sec .RaDatagrid-expandedPanel #expand-table {
    border-top: 1.4px solid #d7d7d7;
    padding: 10px;
    margin: 0 10px;
}
.route-sec .accordian-class .MuiButtonBase-root{
    padding: 0px 10px !important;
}
.route-sec .accordian-class .MuiButtonBase-root:focus{
    color: transparent !important;
}
.route-sec .accordian-class .MuiButtonBase-root h4{
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    margin: 0 2px;
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* .route-sec .accordian-class .MuiButtonBase-root .MuiAccordionSummary-content{
    display: contents;
} */
.route-sec .route-assign .refresh-btn-box {
    right: 138px !important;
}
.route-sec .accordian-class .MuiButtonBase-root .rounded-num {
    border: 1px solid #d2d2d2;
    border-radius: 28px;
    padding: 4px 5px;
    height: 18px;
    line-height: 8px;
    font-size: 10px;
    color: #252F40;
    font-weight: 600;
    margin: 8px 5px 0;
}
.route-sec .accordian-class .MuiButtonBase-root svg {
    fill: #4646F2;
    width: 1rem !important;
    height: 1rem !important;
    margin: 8px 0 8px;
}
.route-sec .accordian-class .MuiAccordionSummary-expandIconWrapper svg path {
    d: path('M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z') !important;
}
.route-sec .accordian-class .MuiAccordionSummary-expandIconWrapper.Mui-expanded svg path {
    d: path('M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z') !important;
}
.route-sec .accordian-class .MuiAccordionSummary-expandIconWrapper{
    transform: none !important;
    transition: none !important;
}
.route-sec .accordian-class{
    margin: 0px 0 !important;
    min-height: auto !important;
}
.route-sec .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0 !important;
}
.route-sec .accordian-class .MuiButtonBase-root.Mui-expanded {
    min-height: 48px !important;
}
.route-sec .accordian-class .MuiAccordionDetails-root p{
    color: #252F40;
    text-align: left;
    font-size: 13px;
    margin-bottom: 4px;
}
.route-sec .accordian-class .MuiAccordionDetails-root h4{
    color: #252F40;
    font-weight: 600;
    font-size: 12px;
}
.route-sec .accordian-class .MuiAccordionDetails-root label{
    color: #252F40;
    font-size: 10px;
}
.route-sec .accordian-class .MuiAccordionDetails-root .MuiGrid-item {
    padding-top: 4px !important;
}
.route-sec .route-assign-acc{
    margin-top: 15px !important;
}
.route-sec .route-assign-acc .MuiButtonBase-root {
    padding: 0px 0px !important;
    min-height: 30px !important;
}
.route-sec .route-assign-acc .MuiAccordionSummary-content{
    margin: 0px !important;
}
.route-sec .route-assign-acc .MuiAccordionDetails-root {
    padding: 0px 14px 0px;
}
.route-sec .MuiTableRow-root.RaDatagrid-expandable{
    position: relative;
    top: 4px;
}
.route-sec .MuiSnackbar-root{
    top: 100px !important;
}
.route-sec .vts-form .status-b .MuiOutlinedInput-root .MuiSelect-select{
    padding: 7.5px 14px !important;
    width: 100px !important;
    margin-left: 8px;
}
.route-sec .MuiOutlinedInput-notchedOutline{
    border: none;
}

.route-toolbar .refreshbtn{
    background: none !important;
    border: 1px solid #4646F2 !important;
    color: #4646F2 !important;
    text-transform: capitalize;
    padding: 7px !important;
    height: 42px;
}
.route-toolbar .create-btn svg{
     fill: #fff !important;
     margin-top: 0 !important;
}
.route-toolbar .create-btn:hover svg{
    fill: #4646F2 !important;
    
}
.route-toolbar .css-i4bv87-MuiSvgIcon-root{
    fill: #4646F2 !important;
    margin-top: 8px !important;
}
.route-toolbar button span svg{
    top: 0 !important;
  }
/* .route-list-sec .show-btn.add{
    min-width: 1px;
    margin-left: -11px;
    padding: 5px !important;
    margin-top: 20px;
  } */
  .remove {
    background: none;
    padding: 2px 6px;
    border: 1px solid #d20000;
    color: #d20000;
    cursor: pointer;
    margin-top: 25px;
    border-radius: 4px;
    font-weight: bold;
  }
  .route-list-sec .show-btn.add span{
    margin-right: 0 !important;
  }
  .route-list-sec .audit-left {
    padding: 15px;
    width: 100%;
  }
  .route-list-sec .audit-left .MuiSelect-select{
    padding: 7.51px 10px 7.51px 5px!important;
  }
  .route-list-sec .audit-left .MuiInputBase-root svg{
    right: -4px !important;
  }
  /* Scroll bar works on chrome, Edge and Safari */

.route-list-sec *::-webkit-scrollbar {
    width: 7px;
  }
  
  .route-list-sec *::-webkit-scrollbar-track {
    background: #EBEBFD;
  }
  
  .route-list-sec *::-webkit-scrollbar-thumb {
    background-color: #4646F2;
    border-radius: 50px;
  }

  .route-list-sec .audit-left .or-hr hr{
    width: 28px;
    height: 0px;
    background: #ababab;
}
.route-list-sec .audit-left .or-hr{
    display: flex;
    align-items: center;
}
.route-list-sec .audit-left .or-hr span{
    font-size: 12px;
    color: #ababab;
    margin: 0 5px;
}
.route-list-sec .audit-left input{
    padding: 8.5px 14px !important;
}
.route-list-sec .audit-left .MuiAutocomplete-root .MuiInput-root{
    background: #f0f0ff !important;
    border-radius: 4px;
    padding: 4px;
}
.route-list-sec .audit-left .MuiAutocomplete-root label+.MuiInputBase-root{
    margin-top: 0px !important;
}
.audit-left .MuiAutocomplete-inputRoot #tags-standard.MuiAutocomplete-input{
    padding: 6.5px 14px !important;
}
.route-list-sec .audit-left .MuiAutocomplete-root .MuiInputLabel-root{
    font-size: 14px !important;
    color: rgba(0, 0, 0, 0.6);
}
.route-list-sec .audit-left .MuiAutocomplete-root #tags-standard-label.MuiInputLabel-root{
    margin-left: 8px;
    top: -9px !important;
    padding: 4px 0;
}

.route-list-sec .audit-left .MuiAutocomplete-root .MuiInputLabel-root[data-shrink="true"]{
    display: none;
}
.route-list-sec .audit-left .MuiAutocomplete-root{
    background: #f0f0ff;
    border-radius: 4px;
}
.route-list-sec .audit-left .MuiAutocomplete-root .MuiFormControl-root{
    margin: 0 !important;
}
.route-list-sec .audit-left .MuiAutocomplete-root .MuiInput-root::before,.route-list-sec .audit-left .MuiAutocomplete-root .MuiInput-root::after{
    border-bottom: none !important;
}
.route-list-sec .audit-left textarea{
    padding: 10px;
    border-radius: 4px;
    border: none;
    font-size: 12px;
    height: 51px !important;
}
.route-list-sec .audit-left textarea:focus{
    outline: none;
}
.route-list-sec .audit-left .show-btn{
    float: right;
}
.route-list-sec .audit-left .show-btn,.route-list-sec .audit-left .refreshbtn{
    padding: 5px !important;
}
.route-list-sec .audit-left .show-btn span,.route-list-sec .audit-left .refreshbtn span{
    margin-right: -2px !important;
}
.route-list-sec .audit-left .show-btn.new{
    background-color: #fff !important;
    color: #4646F2 !important;
    font-size: 12px !important;
}
.change-location{
    display: flex;
}
.change-location a{
    margin-left: 1rem;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    border: 1px solid #000;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
}
.change-location a:hover{
    border-color: #fff;
    background-color: #4646f282;
}
.change-location img{
    height: 15px;
}
.MuiAutocomplete-root.custom .MuiInputBase-root::before, 
.MuiAutocomplete-root.custom .MuiInputBase-root::after{
    border: 0 !important;
}
.route-list-sec .audit-left .show-btn.new:hover{
    background-color: #4646F2 !important;
    color: #FFF !important;
}
.route-list-sec .audit-left .MuiFilledInput-root{
    padding-right: 0 !important;
    padding-left: 0 !important;
    padding-top: 0 !important;
}
.route-list-sec .leaflet-control-container .leaflet-bottom.leaflet-left{
    right: 10px !important;
    bottom: 30px !important;
    left: unset !important;
    top: unset !important;
  }
  .route-list-sec .auto-select .MuiOutlinedInput-root{
    padding: 0;
    padding-right: 0 !important;
    }

.backtopage{
    margin: 10px 0;
    cursor: pointer;
}
.backtopage svg{
    font-size: 18px;
    margin-top: 2px;
    font-weight: bold;
    margin-right: 4px;
}
.backtopage h4{
    font-weight: 600;
    margin-bottom: 3px;
    font-size:13px;
    margin-top:-7px;
}
.route-status svg{
    font-size: 12px !important;
    top: 0 !important;
    margin-right: 4px !important;
}
.route-status.created{
    color: #FA9826 !important;
}
.route-status.created svg{
    fill: #FA9826;
}
.route-status.verified{
    color: #63CE78 !important;
}
.route-status.verified svg{
    fill: #63CE78;
    
}
/* @media only screen and (min-width: 1200px) and (max-width:2000px){
    .route-list-sec  .MuiPaper-root{
        height: 440px !important;
    }
    .route-list-sec  .MuiPaper-root .leaflet-container{
        height: 440px !important;
    }
} */
.custom-label{
    font-size: 14px !important;
    margin-left: 6px;
}
@media (min-width: 1400px){
    .audit-left .react-datepicker .react-datepicker__navigation{
        right: 2px !important;
    }
}

@media (min-width: 2000px){
    .route-list-sec  .MuiPaper-root.first-card{
        height: 75vh !important;
    }
    .route-list-sec  .MuiPaper-root .leaflet-container{
        height: 75vh !important;
    }
    .route-sec .accordian-class .MuiButtonBase-root h4{
        width: 400px !important;
    }
}