.report-left .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select{
    padding: 9px 14px !important;
}
.reports-toolbar{
    margin-right: 4px;
    width: 76%;
}
.reports-toolbar h2{
    position: absolute !important;
    left: -8px !important;
  }
  .reports-toolbar .Addgroup{
    right: 0;
    height: 38px;
    bottom: 12px;
  }
.report-select{
    padding: 0 20px;
}
.report-select tbody tr td.MuiTableCell-root{
    padding: 0!important;
}
.report-select tbody .MuiPaper-root{
    border-radius: 0px 0px 8px 8px !important;
    box-shadow: none !important;
}
.report-select tbody #expand-table tbody tr:nth-child(even){
    background-color: #F5F6FA !important;

}
.report-select table .MuiCheckbox-root{
    display: none;
}
.audit-left .MuiFormControl-root{
    width: 100%;
}
.audit-left .MuiFilledInput-root::after{
    border-bottom: none !important;
}
.audit-left .MuiFormLabel-root.Mui-focused{
    color: rgba(0, 0, 0, 0.6);
}
.audit-left .date-range .MuiFormControl-root{
    background: transparent !important;
}
.audit-left .basic-multi-select .select__control{
    background-color: #f0f0ff;
    border: none;
    font-size: 13px;
}
.audit-left .basic-multi-select .select__indicator-separator{
    display: none;
}
.audit-left .basic-multi-select .select__control .select__indicator,.audit-left .basic-multi-select .select__control .select__value-container{
    padding: 2px !important;
}
.audit-left .basic-multi-select .select__multi-value{
    background-color: hsl(240deg 66% 89%);
}
.audit-left .MuiOutlinedInput-notchedOutline{
    border: none;
}
.audit-left .date-range .MuiFormControl-root{
    margin-left: 0 !important;
  }
  .audit-left  .date-range{
    margin-top: 2px !important;
  }
  .audit-left .date-range .react-datepicker-wrapper input {
    /* width: 94% !important; */
    padding: 19px 6px 15px !important;
  }
  .audit-left .date-range .cal-icon{
    right: 6px !important;
    top: 22px;
  }
  .audit-left.audit-trail .date-range .cal-icon{
    top: 0 !important;
  }
  .audit-left .react-datepicker{
    display: flex;
    justify-content: space-between;
  }
  .audit-left .react-datepicker,.audit-left .react-datepicker-popper{
    width: 100%;
  }
  .audit-left .react-datepicker__month-container{
    width: 100%;
  }
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
    width: 1.4rem !important;
    line-height: 1.4rem !important;
  }
  .audit-left .react-datepicker .react-datepicker__header--time{
    height: 60px;
  }
  .audit-left .react-datepicker .react-datepicker__navigation--next{
    right: 100px !important;
  }
  .audit-left.audit-trail .react-datepicker .react-datepicker__navigation--next{
    right: 26px !important;
  }
  .audit-left .react-datepicker .react-datepicker__navigation--previous{
    left: 15px !important;
  }
  .audit-left .react-datepicker .react-datepicker__navigation{
    top: 8px !important;
  }
  .audit-left .css-1pahdxg-control, .audit-left .css-t3ipsp-control{
    background: #f0f0ff;
    border: none;
    box-shadow: none;
}
.audit-left .css-tlfecz-indicatorContainer svg path {
    d: path('M7 10l5 5 5-5z') !important;
}
.audit-left .css-1gtu0rj-indicatorContainer svg path {
    d: path('m7 14 5-5 5 5H7z') !important;
}
.audit-left .css-tlfecz-indicatorContainer svg, .audit-left .css-1gtu0rj-indicatorContainer svg{
    fill: rgba(0, 0, 0, 0.54);
    width: 1.2rem!important;
    height: 1.2rem !important;
    font-size: 1.5rem;
}
.report-sec .RaDatagrid-tableWrapper{
    /* width: 798px;
    margin-left: 20px; */
    /* margin-top: 60px; */
}
.report-sec{
    width: calc(100% - 390px);
    z-index: 0;
    margin-left: 20px;
}
/* .reptype-toolbar{
    position: absolute !important;
    left: 38%;
} */
.report-sec .reptype-toolbar .MuiToolbar-root{
    width: 100%;
    top: -20px;
    padding-bottom: 0;
}
.report-sec .reptype-toolbar .top-tlcount{
    position: absolute;
    left: 0;
}
.reptype-toolbar .top-tlcount .MuiButton-root {
    background: none !important;
    border: 1px solid #4646F2 !important;
    color: #252F40 !important;
    text-transform: capitalize;
    border-radius: 50px;
    padding: 3px 5px;
    font-size: 12px;
    line-height: 16px;
}
.reptype-toolbar .MuiToolbar-root{
    width: 100%;
}
.reptype-toolbar .MuiToolbar-root .top-tlcount {
    position: absolute;
    left: 0;
}
.reptype-toolbar .top-tlcount .MuiButton-root span{
    margin-right: 0;
}
.reptype-toolbar .show-column-bar{
    float:right;
    /* position: relative;
    left: 84%; */
}
.reptype-toolbar .show-column-bar button{
    margin-left: 38px;
}
.Schedule-modal .checkbox-label .MuiFormLabel-root{
color: #2E2C33;
text-transform: capitalize;
font-size: 14px;
}
.Schedule-modal .date-range .MuiFormControl-root{
    margin-top: 0 !important;
    margin-left: 0 !important;
}
.Schedule-modal .MuiSelect-select,.Schedule-modal input.MuiOutlinedInput-input{
    padding: 0px 2px !important;
}
.Schedule-modal .MuiOutlinedInput-root{
    font-size: 12px !important;
}
.Schedule-modal .date-range .react-datepicker-wrapper input{
    height: 36px !important;
}
.Schedule-modal .date-range .cal-icon{
    right: 5px !important;
}
.Schedule-modal .select-component .MuiFormControl-root .MuiOutlinedInput-root,.Schedule-modal .select-component .MuiFormControl-root .MuiFilledInput-root {
    padding: 0 !important;
    height: 38px !important;
  }
  .Schedule-modal .select-component .MuiFormLabel-root{
    margin-top: -2px;
  }
  .Schedule-modal .select-component .MuiFormLabel-root[data-shrink="true"]{
    display: none;
  }
  .Schedule-modal .select-component .css-319lph-ValueContainer{
    padding: 2px 0px !important;
  }
  .Schedule-modal .select-component .MuiAutocomplete-root input{
    padding: 8.5px !important;
  }
  .Schedule-modal .Mui-focused input{
    padding-top: 8.5px !important;
    padding-bottom: 8.5px !important;
  }
  .Schedule-modal .MuiBox-root .MuiFormControl-root .MuiFilledInput-root{
    height: 38px;
  }
.disable{
    opacity: 0.3;
}
.audit-left .select-drops .MuiFilledInput-input{
    padding-top: 8px !important;
}
.audit-left .select-drops .MuiFilledInput-root.Mui-focused::after{
    display: none !important;
}
.audit-left .select-drops .css-1s2u09g-control, .audit-left .select-drops .css-13cymwt-control{
    background: #f0f0ff;
    border: none;
}
.audit-left .select-drops .css-b62m3t-container{
    z-index: 1;
}
.audit-left .select-drops .css-1okebmr-indicatorSeparator,.audit-left .select-drops .css-1u9des2-indicatorSeparator{
    display: none;
}
.audit-left .select-drops .css-1f43avz-a11yText-A11yText::after{
    outline: none !important;
}
.audit-left .select-drops .css-1xc3v61-indicatorContainer svg{
    fill: rgba(0, 0, 0, 0.54);
    width: 1.2rem!important;
    height: 1.2rem !important;
    font-size: 1.5rem;
}
.audit-left .select-drops .css-1xc3v61-indicatorContainer svg path {
    d: path('M7 10l5 5 5-5z') !important;
}
.audit-left .select-drops .css-15lsz6c-indicatorContainer svg path {
    d: path('m7 14 5-5 5 5H7z') !important;
}
.audit-left .css-14el2xx-placeholder,.audit-left .css-qc6sy-singleValue, 
.audit-left .select-drops .css-1jqq78o-placeholder, .audit-left .select-component .css-1jqq78o-placeholder,
.audit-left .select-component .css-1dimb5e-singleValue,.audit-left .select-drops .css-1dimb5e-singleValue{
    font-size: 12px;
    color: #2E2C33 !important;
    padding-left: 3px;
}
.miscellaneous-page{
    margin-top: 17px;
}
.miscellaneous-page .audit-left .select-component .css-1s2u09g-control, .miscellaneous-page .audit-left .select-component .css-13cymwt-control {
    border: none;
    background: #f0f0ff;
}
.miscellaneous-page .audit-left .select-component .css-t3ipsp-control{
    background: #f0f0ff;
    border: none;
    box-shadow: none;
}
.miscellaneous-page .audit-left .select-component .css-hlgwow{
    padding: 2px 0px !important;
}
.miscellaneous-page .audit-left .select-component .css-1okebmr-indicatorSeparator, .miscellaneous-page .audit-left .select-component .css-1u9des2-indicatorSeparator{
    display: none;
}
.miscellaneous-page .audit-left .select-component .css-1xc3v61-indicatorContainer svg{
    fill: rgba(0, 0, 0, 0.54);
    width: 1.2rem!important;
    height: 1.2rem !important;
    font-size: 1.5rem;
}
.miscellaneous-page .audit-left .select-component .css-1xc3v61-indicatorContainer svg path {
    d: path('M7 10l5 5 5-5z') !important;
}
.miscellaneous-page .audit-left .select-component .css-15lsz6c-indicatorContainer svg path{
    d: path('m7 14 5-5 5 5H7z') !important;
}
.miscellaneous-page .miscellaneous .text-field .MuiInputBase-root .MuiInputBase-input{
    padding: 7.88px 5px !important
}
.select__menu{
    z-index: 99999 !important;
    position: relative;
}
.audit-left .select-component .css-26lqy-menu,.select-component .listbox, 
.audit-left .select-component .css-26lqy-menu .listbox, .audit-left .select-component .css-26l3qy-menu,
.audit-left .select-component .css-26l3qy-menu .listbox,
.audit-left .select-drops .css-1nmdiq5-menu,
.audit-left .select-drops .css-1nmdiq5-menu .listbox, .audit-left .select-component .css-1nmdiq5-menu{
    font-size: 12px !important;
}

@media only screen and (min-width: 1460px) and (max-width:1600px){
    .grid-box{
        width: 71.5% !important;
    }
    .audit-left .react-datepicker,.audit-left .react-datepicker-popper{
        width: 86%;
      }
}
@media only screen and (min-width: 1600px) and (max-width:2000px){
    .grid-box{
        width: 74% !important;
    }
    .audit-left .react-datepicker,.audit-left .react-datepicker-popper{
        width: 82%;
      }
}
@media only screen and (min-width: 2001px) and (max-width:2400px){
    .miscellaneous-page .MuiPaper-root, .rep-vehicle-tab .MuiPaper-root{
        height: 75vh !important;
    }
    .audit-trails-tab .MuiPaper-root{
        height: 69.2vh !important;
    } 
    .grid-box{
        width: 75% !important;
    }
    .audit-left .react-datepicker,.audit-left .react-datepicker-popper{
        width: 69%;
      }
}
@media only screen and (min-width: 2401px) and (max-width:3000px){
    .audit-left .react-datepicker,.audit-left .react-datepicker-popper{
        width: 70%;
      }
}

/* @media only screen and (min-width: 565px) and (max-width:1400px) {
    .report-sec .RaDatagrid-tableWrapper{
        width: 768px!important;
    }
}

@media only screen and (min-width: 1401px) and (max-width:1500px) {
    .report-sec .RaDatagrid-tableWrapper{
        width: 992px!important;
    }
}
@media (min-width: 1501px){
    .report-sec .RaDatagrid-tableWrapper{
        width: 1200px!important;
    }
} */