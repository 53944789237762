.Alarm-toolbar .top-tlcount{
    position: absolute;
    left: -8px;
    bottom: 10px;
}
.Alarm-toolbar {
    margin-right: 4px;
    width: 76%;
}
.alarm-modal textarea{
    border-radius: 4px !important;
}
.alarm-modal textarea:focus{
    box-shadow: none;
    outline: none !important;
}
.alarm-modal .css-1tk91c2-MuiFormLabel-root{
    font-size: 14px !important;
}
.alarm-modal .MuiButton-root{
    text-transform: capitalize !important;
}
.alarm-modal .modal-box {
    width: 400px !important;
}
.alarm-modal .modal-box .alarm-check input[type="checkbox"],.alarm-modal .modal-box input[type="checkbox"].alarm-check {
    height: 16px !important;
    position: relative;
    bottom: 25px;
    float: left;
    left: 45px;
    background: transparent !important;
    width: 20px;
}
.alarm-modal.edit input[type="checkbox"].alarm-check {
bottom: 18px !important;
} 
.alarm-modal .alarm-check .MuiOutlinedInput-notchedOutline,.alarm-modal .alarm-check .MuiOutlinedInput-root{
    background: transparent !important;
    border: none !important;
}
.alarm-check .MuiFilledInput-root{
    background: transparent!important;
}
.alarm-modal .MuiAutocomplete-root .MuiInputBase-root{
    padding: 2px !important;
    padding-right: 2px !important;
}
.alarm-modal .MuiAutocomplete-popper .MuiAutocomplete-listbox{
    height: 180px;
    z-index: 9999; 
  }
  .alarm-modal .MuiSelect-select{
    padding: 8.5px !important;
  }
.addalarm:hover{
    width: 115px !important;
}
button.addalarm{
    border-radius: 6px !important;
    color: #FFFFFF;
    font-size: 13px;
    height: 38px;
    right: 0;
    bottom: 12px;
  }
  button.addalarm svg{
    width: 1rem !important;
    height: 1rem !important;
  }
  button.addalarm span.MuiButton-startIcon{
    margin-right: 4px !important;
  }
.alarm-modal .modal-footer button:first-child{
    margin-right: 0 !important;
}
.alarm-modal .modal-box h2#modal-modal-title{
    color:#363B45;
    font-weight: bold !important;
    font-size: 17px !important;
}
.alarm-modal .modal-box .MuiFormLabel-root{
    color: #67748E;
    font-size: 12px !important;
    font-weight: normal;
}
.alarm-modal .modal-box .Switchbtn span.MuiTypography-root{
    color: #252F40;
    font-size: 14px;
}
.address_styling{
    text-decoration: underline;
    cursor:pointer;
    color: blue;
}
.address_len{
    text-overflow: ellipsis;
    display: block;
    max-width: 150px;
    overflow: hidden;
}