.Adduser:hover{
    background: none !important;
    border:1px solid #4646F2;
    color: #000 !important;
  }
.Adduser .MuiSvgIcon-root{
  fill: #fff !important;
  margin-top: 8px;
}
.Adduser:hover .MuiSvgIcon-root{
  fill: #4646F2 !important;
}
.AdduserModal .modal-box,.AddRoleModal .modal-box{
border: none !important;
padding: 20px 30px !important;
border-radius: 6px !important;
width: 740px;
}
.AdduserModal #modal-modal-title,.AddRoleModal #modal-modal-title{
text-align: center;
font-size: 18px !important;
font-weight: 600 !important;
}
.AdduserModal #modal-modal-title{
  text-align: left !important;
  color: #363B45;
}
.AdduserModal h4,.AddRoleModal h4{
font-size: 15px !important;
text-align: left !important;
color: #363B45 !important;
}
.AdduserModal .css-16f1609{
display: flex !important;
flex-direction: column !important;
}
.AddRoleModal .css-16f1609 .MuiFormLabel-root{
    margin-right: 20px;
    padding-top: 17px;
    line-height: 40px;
  }
.AddRoleModal .rolename-top{
  padding-bottom: 12px;
}
.AddRoleModal .rolename-top label{
    line-height: 15px;
    margin-right: 20px;
    padding-top: 3px;
    top: 10px;
}
/* .css-1q7njkh {
  margin-top: 0px !important;
} */
.AdduserModal input,
.AdduserModal .MuiOutlinedInput-root,
.AddRoleModal input,
.AddRoleModal .MuiOutlinedInput-root,.AdduserModal .MuiFilledInput-root,.AddRoleModal .MuiFilledInput-root{
background: #f0f0ff !important;
/* height: 5px !important; */
border: none !important;
border-radius: 5px !important;
font-size: 12px;
color: #000;
padding: 0px 6px;
outline: none !important;
}
.AdduserModal input,.AddRoleModal input{
  padding: 8.5px 10px !important;
}
.AdduserModal .MuiFormHelperText-root {
  margin-left: 0;
  position: absolute;
  top: 34px;
}

.AdduserModal .Mui-focused input,.AddRoleModal .Mui-focused input{
  padding-top: 8.5px !important;
  padding-bottom: 8.5px !important;
}
.AdduserModal .MuiSelect-select{
  padding: 12px 12px 4px !important;
}
.AdduserModal input::after,.AdduserModal input::before{
  border-bottom: none!important;
}
.AdduserModal .MuiBox-root .MuiFormControl-root .MuiFilledInput-root {
  background-color: #f0f0ff!important;
  border: none!important;
  border-radius: 5px!important;
}
.AdduserModal .MuiFilledInput-root::after,.AdduserModal .MuiFilledInput-root::before,.AdduserModal .MuiFilledInput-root:hover::before{
  border-bottom: none!important;
}
.AdduserModal .MuiFilledInput-root:hover,.AddRoleModal .MuiFilledInput-root:hover{
  border-bottom: none !important;
}
/* .AddRoleModal input[type="checkbox"]{
  height: auto !important;
} */
.AdduserModal .MuiTextField-root .MuiOutlinedInput-notchedOutline,
.AdduserModal .MuiTextField-root .MuiOutlinedInput-notchedOutline:hover,
.AddRoleModal .MuiTextField-root .MuiOutlinedInput-notchedOutline,
.AddRoleModal .MuiTextField-root .MuiOutlinedInput-notchedOutline:hover {
border: 1px solid #fffefe !important;
}
.AdduserModal .MuiFormLabel-root,.AddRoleModal .MuiFormLabel-root{
font-size:14px !important;
}
.AdduserModal .MuiOutlinedInput-root,
.AddRoleModal .MuiOutlinedInput-root{
height: 38px !important;
}
.AdduserModal .modal-footer button:first-child,
.AddRoleModal .modal-footer button:first-child{
background-color: #4646F2 !important;
margin-right: 17px;
float: right;
border: 1px solid #4646F2;
}
.AdduserModal .modal-footer button:first-child:hover,
.AddRoleModal .modal-footer button:first-child:hover{
background-color: #fff !important;
color: #4646F2 !important;
border: 1px solid #4646F2 !important;
}
.modal-footer button{
text-transform: capitalize !important;
}
.AdduserModal .cancel-btn,.AddRoleModal .cancel-btn{
background-color: #fff !important;
border: 1px solid #4646F2 !important;
color: #000;
float: right;
}
.AdduserModal .css-ahj2mt-MuiTypography-root{
font-size: 13px !important;
}
.AdduserModal .MuiFormControl-root{
  width: 100%;
  margin-top: 0 !important;
}
.AdduserModal .MuiOutlinedInput-notchedOutline,.AddRoleModal .MuiOutlinedInput-notchedOutline{
  border: none !important;
}
.AdduserModal .left-side {
  background: #F0F2F5;
  padding-left: 0 !important;
  padding-top: 30px !important;
}
.AdduserModal .left-side .Addrole-tabs .MuiTabs-flexContainer button{
  padding: 10px 10px;
  font-size: 13px;
  margin-right: 0 !important;
  border-radius: 0 !important;
  padding-left: 20px;
  line-height: 28px;
}
.AdduserModal .left-side .Addrole-tabs .MuiTabs-flexContainer button{
  border-bottom: none !important;
  color: #2E2C33;
}
.AdduserModal .left-side .Addrole-tabs .MuiTabs-indicator{
  background-color:transparent !important;
}
.AdduserModal .left-side .Addrole-tabs .Mui-selected{
  background-color: #fff;
  color: #363B45 !important;
}
.AdduserModal .right-side h4{
  font-size: 14px !important;
  font-weight: 600;
}
.AdduserModal .right-side .left-checkbox input[type="checkbox"]{
  position: relative;
  top: 2px;
}
.AdduserModal .right-side .left-checkbox .MuiBox-root{
  margin-right: 20px;
}
.AdduserModal .right-side .left-checkbox .MuiBox-root label{
  margin-left: 10px;
}
.AdduserModal .right-side form{
  height: 350px;
}
.AdduserModal  .div-box{
  height: 348px;
  overflow: auto;
}
.AdduserModal .right-side .modal-footer button:first-child{
  margin-right: 0;
}
.Adduser:hover{
  width: 115px !important;
  padding: 5px 0 !important;
}
button.Adduser{
  border-radius: 6px !important;
  color: #FFFFFF;
  font-size: 13px;
  height: 38px;
  right: 0;
  bottom: 12px;
}
button.Adduser svg{
  width: 1rem !important;
  height: 1rem !important;
}
button.Adduser span.MuiButton-startIcon{
  margin-right: 4px !important;
}
/* .AdduserModal .MuiFormControl-root .MuiInputLabel-root.MuiInputLabel-animated.Mui-focused{
    display: none;
}
.AdduserModal #select-formcontrol .MuiOutlinedInput-root {
  top: -21px;
}
.AdduserModal #select-formcontrol .MuiOutlinedInput-root.Mui-focused {
  top: 0;
}
.AdduserModal .MuiFormControl-root .MuiInputLabel-root{
  position: relative;
  top: -8px;
} */
.Addrole-tabs .MuiTabs-flexContainer{
  flex-direction: column !important;
}

/* Scroll bar works on chrome, Edge and Safari */

.AddRoleModal *::-webkit-scrollbar {
  width: 11px;
}

.AddRoleModal *::-webkit-scrollbar-track {
  background: #EBEBFD;
}

.AddRoleModal *::-webkit-scrollbar-thumb {
  background-color: #4646F2;
  border-radius: 50px;
}

.AddRoleModal .left-side{
  background: #f8f8f8;
  padding-left: 0 !important;
}
.AddRoleModal .left-side .MuiButtonBase-root-notchedOutline.Mui-selected{
  background: #fff !important;
}
.AddRoleModal .left-side .css-1aquho2-MuiTabs-indicator{
  background-color: transparent !important;
}
.AddRoleModal .left-side h5{
    padding: 8px 16px;
    color: #979797 !important;
    font-size: 13px !important;
}
.AddRoleModal .left-side .Addrole-tabs .MuiTab-root.Mui-selected {
  color: #000;
  background: #fff;
  margin-right: 0 !important;
  border-radius: 0 !important;
}

.Addrole-tabs .MuiTabs-flexContainer button{
  text-align: left !important;
  -webkit-align-items: flex-start;
    justify-content: flex-start;
}
/* .AddRoleModal .MuiGrid-root>.MuiGrid-item {
  padding-top: 0px;
} */
.AddRoleModal .css-xnln8x{
  margin-top: 17px !important;
}
.AddRoleModal .css-1aquho2-MuiTabs-indicator{
background-color: transparent !important;
}
.AddRoleModal .MuiButtonBase-root-notchedOutline:hover:active::after,
.AddRoleModal .MuiButtonBase-root-notchedOutline:focus::after{
  background-color: transparent !important;
}
.AddRoleModal .MuiTabs-root .MuiButtonBase-root{
  border-bottom:none !important
}
.AddRoleModal .MuiFilledInput-root::before,.AddRoleModal .MuiFilledInput-root::after,.AddRoleModal .MuiFilledInput-root:hover::before{
  border-bottom: none !important;
}
.AddRoleModal .MuiTabs-indicator{
  background-color: transparent !important;
}
.AddRoleModal .rolename input#rolename {
  padding: 7px 4px 7px;
}

.permis-left{
  background: #eee;
  border-radius: 5px;
  padding: 15px;
}
.permis-right input[type="date"]{
  background: #f0f0ff !important;
    height: 30px !important;
    border: none !important;
    border-radius: 5px !important;
    font-size: 14px;
    color: #000;
    padding: 5px;
}
.permis-right .MuiFormLabel-root{
  padding-top: 0 !important;
}
.card-dropdown .MuiSelect-select{
  padding: 0 !important;
}
.card-dropdown .MuiFormLabel-root{
  font-size: 13px !important;
}
.card-dropdown .MuiOutlinedInput-root{
  height: 30px !important;
}
.treeview{
  margin-top: 15px !important;
  max-width: 100% !important;
}
.treeview .MuiCheckbox-root{
  /* position: absolute; */
  padding: 0;
  padding-top: 8px;
  padding-left: 8px;
}
.treeview .MuiCheckbox-root input[type="checkbox"]{
  position: absolute;
  top: 11px;
}
.treeview .MuiCheckbox-root:hover:active::after,.treeview .MuiCheckbox-root:focus::after,.treeview .MuiCheckbox-root:hover{
  background-color: transparent !important;
}
.treeview .MuiTreeItem-root{
  /* margin-bottom: 10px !important; */
  position: relative;
  bottom: 24px;
}
.treeview .MuiTreeItem-content .MuiTreeItem-label{
  padding: 5px !important;
  font-size: 13px !important;
  }
.treeview .MuiTreeItem-content.Mui-selected .MuiTreeItem-label{
    font-weight: 500 !important;
  }
.treeview.MuiTreeView-root{
    height: 340px !important;
    overflow-x: hidden !important;
  }
  .Role-table{
    height: 370px;
    overflow: auto;
    padding-right: 5px;
    box-shadow: none !important;
  }
  .Role-table table{
    border: 1px solid #f8f8f8;
    border-radius: 2px !important;
  }
  .Role-table table tbody tr td,.Role-table table tbody tr th{
    padding: 0px 16px !important;
  }
  .Role-table table thead tr th{
    padding: 10px 16px !important;
  }
  .Role-table table thead tr{
  background: #f8f8f8;
  position: sticky;
  top: -1px;
  z-index: 9;
 }
  .map-left-card .expand-btn{
    background: #4646F2 !important;
    border: none !important;
    margin-left: 0px !important;
    width: 60px !important;
    height: 42px !important;
    padding: 1px 1px 1px 1px;
    /* position: relative !important;
    left: 19px !important; */
    border-radius: 5px 0 5px 0px !important;
    /* top: 21px; */
    cursor: pointer;
  }
  .map-left-card  .expand-btn .MuiSvgIcon-root{
    fill: #fff !important;
  }
  .expand-btn .MuiButton-startIcon{
    margin-right: -6px !important;
  }
  .map-left-card .MuiCardActions-root{
    position: relative !important;
    /* top: 16px !important; */
    left: 0px !important;
    display: inline-block !important;
    float: right;
}
.map-left-card  .MuiCardActions-root .MuiSvgIcon-root{
  fill: #fff !important;
  margin-left: 22px !important;
  margin-top: 12px !important;
  border: 1.5px solid #fff;
  border-radius: 13px;
  width: 0.8em;
  height: 0.8em;
}
.map-left-card{
  height: auto !important;
}
.map-left-card .information{
  margin-top: 15px !important;
}
.map-left-card .information {
  background: #eee;
  border-radius: 5px;
  padding: 15px;
  margin-right: 15px;
}
.map-left-card .information h4{
  font-size: 12px !important;
  font-weight: 600 !important;
}
.map-left-card .information h1{
  font-size: 14px !important;
  font-weight: 600 !important;
}
.map-left-card .information .MuiFormLabel-root{
  font-size: 13px !important;
}
.map-left-card .information .MuiGrid-root>.MuiGrid-item {
  padding-top: 4px !important;
}
.map-left-card .information .MuiGrid-root{
  margin-top: 0 !important;
}
.map-left-card .f-leftB{
  margin-left: 0 !important;
}
.map-left-card .status-icon.MuiSvgIcon-root{
  fill: #F25555 !important;
  float: right;
  font-size: 12px;
  margin-top: 8px !important;
}
.map-left-card .status-icon-green.MuiSvgIcon-root{
  fill: #55f261 !important;
  float: right;
  font-size: 12px;
  margin-top: 8px !important;
}
.map-left-card h4 .Card-Icons.MuiButton-root:hover {
  border: none !important;
}
.map-left-card .status-btn[status="Moving"],.map-left-card .status-btn[status="Running"],.map-left-card .status-btn[status="towing"],.map-left-card .status-btn[status="moving"]{
  background:#63CE78;
  border:1px solid #63CE78;
  color:#252F40 !important;
}
.map-left-card .status-btn[status="Idle"],.map-left-card .status-btn[status="idle"]{
  background:#FA9826 !important;
  border:1px solid #FA9826;
  color:#252F40 !important;
}
.map-left-card .status-btn[status="Stopped"],.map-left-card .status-btn[status="stopped"] {
  background:#F25555 !important;
  border:1px solid #F25555;
  color:#252F40 !important;
}
.map-left-card .status-btn[status="Inactive"],.map-left-card .status-btn[status="inactive"] {
  background:#EFD54B  !important;
  border:1px solid #EFD54B;
  color:#252F40 !important;
}
.map-left-card .status-btn[status="other"],.map-left-card .status-btn[status="Other"]{
  background:blue;
  border:1px solid blue;
  color:#fff !important;
}

.map-left-card .status-btn:hover{
  background: #fff !important;
  color: #252F40 !important;
}

.status-list{
  cursor: pointer;
  text-transform: capitalize;
}
.status-list[status="Moving"],.status-list[status="Running"],
.status-list[status="moving"],.status-list[status="Towing"],.status-list[status="towing"]{
  color:#63CE78;
}
.status-list[status="Idle"],.status-list[status="idle"]{
  color:#FA9826;
}
.status-list[status="Stopped"],.status-list[status="stopped"] {
  color:#F25555;
}
.status-list[status="Inactive"],.status-list[status="inactive"] {
  color:#000;
}
.status-list[status="other"],.status-list[status="Other"]{
  color:#530ad1;
}
.status-list[status="gpsremoved"],.status-list[status="gpsremoved"]{
  color: #c0c0c0;
}

.action-icons{
  display: flex;
}
.action-icons .MuiSvgIcon-root{
  font-size: 16px !important;
  fill: #252F40 !important;
  cursor: pointer !important;
}
.action-icons .MuiSvgIcon-root:hover{
  fill: #4646F2 !important;
}
.refreshbtn{
  background-color: transparent !important;
  border: 1px solid #4646F2 !important;
  color: #4646F2 !important;
  padding: 4.5px 10px !important;
  text-transform: capitalize !important;
  margin-right: 10px !important;
  z-index: 999;
  font-size: 13px !important;
  height: 38px;
  bottom: 8px;
}
.refreshbtn span{
  margin-right: 4px;
}
.refreshbtn .MuiSvgIcon-root{
  fill: #4646F2 !important;
  margin-top: 2px !important;
}
 .MuiToolbar-root .MuiButton-sizeSmall[aria-label="Export"] svg{
  fill: #fff !important;
  margin-top: 8px !important;
 }
 .MuiToolbar-root .MuiButton-sizeSmall[aria-label="Export"]:hover svg{
  fill: #4646F2 !important;
 }
 .MuiToolbar-root .MuiButton-sizeSmall[aria-label="Export"] span{
  margin-right: 2px!important;
 }
.user-toolbar{
  margin-right: 4px;
  width: 76%;
} 
.user-toolbar h2{
  position: absolute !important;
  left: -8px !important;
  bottom: 8px;
}

.MuiTabs-root .MuiButtonBase-root {
  border-bottom: 3px solid #787878 !important;
  border-radius: 8px 8px 0 0 !important;
  margin-right: 12px !important;
}
.MuiDialog-container .MuiDialogActions-root .MuiButton-root:first-child{
  border: 1px solid #4646F2;
  background: #fff !important;
  color: #4646F2 !important;
}
.MuiDialog-container .MuiDialogActions-root .MuiButton-root{
  border: 1px solid #4646F2;
  background: #4646F2 !important;
  color: #fff !important;
}
.MuiDialog-container .MuiDialogActions-root .MuiButton-root:first-child .MuiSvgIcon-root{
  fill: #4646F2 !important;
  margin-top: 6px;
}
.MuiDialog-container .MuiDialogActions-root .MuiButton-root:first-child span{
  margin-right: 4px !important;
}
.MuiDialog-container .MuiDialogActions-root .MuiButton-root:first-child{
  color: #4646F2 !important;
}
.MuiDialog-container .MuiDialogActions-root .MuiButton-root span{
  margin-right: 4px !important;
}
.MuiDialog-container .MuiDialogActions-root .MuiButton-root .MuiSvgIcon-root{
  fill: #fff !important;
  margin-top: 6px;
}
.MuiDialog-container .MuiDialogActions-root .MuiButton-root:hover{
  color: #fff;
}
.MuiDialog-container h2{
    font-size: 18px;
    font-weight: 600;
    text-align: center;
}
.close-btn{
    position: relative;
    float: right;
    bottom: 30px;
    cursor: pointer;
}

.css-me6g3x {
  margin-bottom: 5px;
}
.css-1ay9vb9{
  margin-right: 0 !important;
}

.MuiToolbar-root .MuiButton-root .MuiButton-startIcon svg{
  position: relative;
  top: 3px;
}
.MuiToolbar-root .MuiToolbar-root .MuiButton-sizeSmall:nth-child(2):hover{
  background-color: #fff !important;
  border: 1px solid #4646F2 !important;
  color: #4646F2 !important;
}
.user-toolbar button span svg{
  top: 0 !important;
}

.AdduserModal .MuiOutlinedInput-input::before,.AdduserModal .MuiOutlinedInput-input::after{
  border-bottom: none !important;
}
.AdduserModal .select-auto.MuiAutocomplete-root .MuiInputLabel-root[data-shrink="true"],
.AdduserModal .MuiFilledInput-root::after{
  display: none !important;
}
.AdduserModal .select-auto.MuiAutocomplete-root .MuiAutocomplete-input {
  padding-bottom: 7px !important;
  padding-top: 10px !important;
  padding-left: 8px;
}
.AdduserModal .select-auto.MuiAutocomplete-root .MuiInputLabel-root{
  padding-left: 5px !important;
  line-height: 10px;
  font-size: 12px !important;
}
.AdduserModal .select-auto.MuiAutocomplete-root .MuiFilledInput-root{
  padding-top: 0px !important;
  padding-right: 0 !important;
}
.AdduserModal .select-auto.MuiAutocomplete-root .MuiFormControl-root{
  margin-top: 0px !important;
}
/* vehicle page css */
  .vehicle-toolbar{
    margin-right: 4px;
  }
.vehicle-sec .MuiToolbar-root .MuiButton-sizeSmall {
  line-height: 1.5;
  background: #4646F2;
  padding: 8px;
  color: #fff;
  text-transform: capitalize !important;
  border: 1px solid #4646F2;
  width: 96px;
  height: 38px;
  bottom: 4px;
}
.vehicle-sec .MuiToolbar-root .top-tlcount{
  position: relative;
  bottom: 4px;
}
.vehicle-sec .MuiToolbar-root .MuiButton-sizeSmall:hover{
  background: #FFF;
  color: #4646F2 !important;
  border: 1px solid #4646F2 !important;
}
.vehicle-toolbar .MuiButton-root .MuiButton-startIcon svg{
  top: 0px;
}

.MuiPaper-root h4 svg{
  cursor: pointer;
}
.vehicle-sec .css-4z3osy{
  flex-wrap: unset !important;
}
.vehicle-sec .map-cards h4{
  color: #252F40;
  font-size: 14px;
  font-weight: bold;
  opacity: 0.94;
  line-height: 20px !important;
  /* display: flex; */
}
.vehicle-sec .map-cards .mapcard-icons img{
  margin-top: 10px;
  margin-right: 9px;
  height: 24px;
  cursor: pointer;
}
.vehicle-sec .map-cards p{
  color: #67748E;
  font-size: 12px;
}
.vehicle-sec .map-cards .mapexpand-icons p{
  margin: 4px;
}
.vehicle-sec .map-cards button{
  color: #fff !important;
  font-size: 10px;
  font-weight: normal !important;
  margin-right: 5px;
}
.vehicle-sec .map-cards button.Card-Icons{
  margin-right: 9px !important;
}
.vehicle-sec .map-cards button.MuiButtonBase-root svg {
  fill: #67748E !important;
  width: 0.7em !important;
  height: 0.7em !important;
}
.vehicle-sec .map-cards h4 button span{
  margin-left: 8px !important;
  margin-top: -6px;
}
.vehicle-sec .map-cards h4 button span img{
  height: 18px;
}

/* Scroll bar works on chrome, Edge and Safari */

.vehicle-sec .map-cards  *::-webkit-scrollbar {
  width: 7px;
}

.vehicle-sec .map-cards *::-webkit-scrollbar-track {
  background: #EBEBFD;
}

.vehicle-sec .map-cards  *::-webkit-scrollbar-thumb {
  background-color: #4646F2;
  border-radius: 50px;
}
.total-count-tab{
  position: absolute;
  /* top: 193px; */
  margin-top: 0 !important;
}
.no-records{
  text-align: center;
  margin-top: 150px;
}
.error-style{
  position: relative;
  bottom: 28px;
}