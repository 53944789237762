.trail-page .MuiFormControl-root{
    width: 100%;
    height: 32px !important;
}
.trail-page .MuiInputBase-input{
    width: 100%;
    /* height: 0px !important; */
    padding: 8px 0px;
}
.trail-page .select-device input{
  padding: 8.5px !important;
}
.trail-page .css-13brihr{
    width: 100%;
}
/* .trail-page .trail-track{
  display: flex;
  flex-direction: column;
} */
.trail-page .css-16f1609{
    height: 54px;
    margin-top: 0rem;
}
.trail-playback p {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
  font-size: 13px;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
}
.trail-playback h4{
  /* word-break: break-word; */
  color: #252F40;
  font-size: 13px;
  font-weight: 600;
  opacity: 0.94;
  line-height: 20px !important;
}
.trail-page .MuiAutocomplete-paper,.trail-page .MuiAutocomplete-popper{
  height: 280px !important;
}
.trail-page .MuiAutocomplete-popper .MuiAutocomplete-listbox{
  min-height: 280px !important;
}
.trail-page .Card-Title{
  color:#252F40;
  font-size: 15px;
  font-weight: bold;
  line-height: 16px;
}
.trail-page .Card-Icons{
  border-color: #252F3F !important;
}
.trail-page .Card-Icons.two:hover{
  border: none !important;
}
.trail-page .select-device .MuiFilledInput-root{
  padding: 0 !important;
}
.trail-page .select-device .MuiInputLabel-root{
  line-height: 12px!important;
}
.line-point{
  position: relative;
  left: 82%;
}
.line-point .line{
  margin-right: 10px;
  margin-bottom: 8px;
  color: #252F40;
  font-size: 15px;
}
.line-point span.MuiTypography-root{
  color: #252F40;
  font-size: 15px;
}
.scrollbar{
  height:65vh;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}
/* Scroll bar works on chrome, Edge and Safari */

.trail-page .list-content *::-webkit-scrollbar,.trail-page .map-content *::-webkit-scrollbar{
  width: 7px;
}

.trail-page .list-content *::-webkit-scrollbar-track,.trail-page .map-content *::-webkit-scrollbar-track{
  background: #EBEBFD;
}

.trail-page .list-content *::-webkit-scrollbar-thumb,.trail-page .map-content *::-webkit-scrollbar-thumb{
  background-color: #4646F2;
  border-radius: 50px;
}




.trail-page .map-content .react-datepicker *::-webkit-scrollbar{
  width: auto;
}

.trail-page .map-content .react-datepicker *::-webkit-scrollbar-track{
  background: #f1f1f1;
}

.trail-page .map-content .react-datepicker *::-webkit-scrollbar-thumb{
  background-color: #c1c1c1;
  border-radius: 0;
}

.trail-page .map-content .react-datepicker .ul.react-datepicker__time-list{
  width: 98% !important;
}

.trail-page .map-content .datefilter .MuiOutlinedInput-root, .trail-page .map-content .datefilter .MuiOutlinedInput-root{
  padding: 0 0  !important;
}
.trail-page .map-content .datefilter .MuiOutlinedInput-notchedOutline{
  border: 0 !important;
}
.trail-page .map-content .datefilter div#mui-component-select-dateselect{
  padding: 5.7px 0 5.7px 8px !important;
}
.trail-page .map-content .react-datepicker .react-datepicker__header--time {
  height: 60px;
}
.trail-page .map-content .date-range .MuiFormControl-root {
  background: transparent!important;
}
.trail-page .map-content .date-range .react-datepicker-wrapper input {
  padding: 19px 6px 15px!important;
}
.trail-page .map-content .date-range .cal-icon {
  right: 6px!important;
  top: 22px;
}

/* .trail-page .total-count-tab{
  top:50px !important;
} */
.trail-page .date-range .MuiFormControl-root{
  margin-left: 0 !important;
}
.trail-page  .date-range{
  margin-top: 1rem;
}
.trail-page .date-range .react-datepicker-wrapper input {
  /* width: 96% !important; */
}
#menu-dateselect .MuiMenu-list li{
  font-size: 12px !important;
}
.trail-page .date-range .react-datepicker-popper{
  z-index: 9999;
}
.trail-page .date-range .cal-icon{
  right: 3px !important;
}

.trail-page p.MuiTypography-root.MuiTypography-body1{
  margin-top: 15px;
}
.trail-page input[type="date"]{
    height: 32px;
    min-height: 38px;
    background: #f0f0ff;
    border: 1px solid #eee;
    border-radius: 3px;
}
.trail-page label{
  font-size: 14px;
}
.trail-page .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.trail-page .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root:hover .MuiOutlinedInput-notchedOutline{
    /* border: 1px solid #eee; */
    border: none;
}
.trail-page .MuiOutlinedInput-root,.trail-page .MuiAutocomplete-root .MuiFilledInput-root  {
  padding: 9px 0 !important;
}
.trail-page .MuiAutocomplete-root .MuiFilledInput-root {
  padding-top: 8px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.trail-page .MuiInputLabel-root {
  line-height: 22px !important;
}
.trail-page .select-device .MuiAutocomplete-root .MuiFilledInput-root{
    width: 100%;
    background: #f0f0ff;
    padding: 0 !important;
}
.trail-page .select-device .MuiAutocomplete-root .MuiFilledInput-root::after{
  border-bottom:none !important;
}
.trail-page .select-device .MuiAutocomplete-root .MuiInputLabel-root[data-shrink="true"]{
  display: none;
}
.trail-page .left-playtrail{
  height: auto;
  overflow-x:hidden;
  overflow-y: auto;
}
.trail-page .MuiPaper-root{
    height: 73vh;
    margin-bottom: 15px;
}

.trail-page .progress {
    position: relative;
    padding: 0;
    margin: 0 0;
    list-style: none;
  }
  
.trail-page .progress__item {
    position: relative;
    min-height: 75px;
    counter-increment: list;
    padding-left: 0.5rem;
  }
.trail-page .progress__item:before {
      content: "";
      position: absolute;
      left: 7rem;
      top: 18px;
      height: 92%;
      width: 1px;
      border-left: 1.5px dotted green;
    }
.trail-page .progress__item:after {
      content: "";
      position: absolute;
      top: 0;
      left: 6.5rem;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #aeaeae;
      color: #fff;
      font-weight: 600;
      font-size: 13px;
      line-height: 1rem;
      text-align: center;
      border: 1px dotted #aeaeae;
    }
   
.trail-page .progress__item:last-child:before {
        border: none;
    }
   
.trail-page .progress__item.progress__item--completed:after  {
        opacity: 0.6;
        content: "\2713";
        font-weight: 600;
        background: green;
        color: #fff;
    }
   
.trail-page .progress__item.progress__item--active:after  {
        background: #aeaeae;
        color: #fff;
  }
  
.trail-page .progress__title {
    padding: 0.4rem 0 0.5rem;
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    width: 120px;
    position: relative;
    top: -6px;
  }
  
.trail-page .progress__info {
    font-size: 13px;
    position: relative;
    left: 8rem;
    text-align: left;
    top: -41px;
    width: 188px;
  }
 
  .trail-track h4{
    color: #2E2C33;
    font-size: 13px;
    font-weight: 600;
    margin-left: 10px;
    line-height: 20px;
  }
  .trail-track svg{
    border: 1px solid #2E2C33;
    border-radius: 12px;
    font-size: 14px;
  }
  .trail-page  .trail-track  .progress__item{
    padding-left: 0 !important;
  }
  .trail-page .trail-track  .progress__title{
    width: 77px !important;
  }
  .trail-page .trail-track  .progress__item:before{
    left: 5.5rem !important;
  }
  .trail-page .trail-track .progress__item:after{
    left: 5rem !important;
  }
  .trail-page .play-trail-map .leaflet-container {
    height: 390px !important;
  }
  .trail-page .trail-track .progress__info{
    left: 6.5rem !important;
    width: 180px !important;
  }
  .trail-track-det{
    float: right;
    margin-top: -25px;
  }
  .trail-track-det svg{
    border: none !important;
    margin-top: 2px;
  }
  .trail-track .play-btn{
    border: 1px solid #4646F2 !important;
    color: #4646F2 !important;
    border-radius: 5px;
    text-transform: inherit;
    margin-top: 15px;
    margin-right: 10px;
    padding: 8px;
  }
  .trail-track .play-btn span{
    margin-right: 4px !important;
  }
  .trail-track .play-btn svg{
    border: none !important;
    fill: #4646F2;
    margin-bottom: -4px;
  }
  .trail-track .arrow-icon{
    border: none !important;
    font-size: 18px !important;
    margin-top: -2px;
  }
  #trackMain .play-controls-btns{
    position: absolute;
    left: 88px;
    top: 38px;
  }
  #trackMain .play-controls-btns button{
    background: green;
    color: #fff;
    border: none;
    font-size: 15px;
    border-radius: 5px;
    padding: 3px 10px;
  }
  .route-indicators {
    display: flex!important;
    position: absolute;
    right: 12px;
  }
  .route-indicators p{
    color: #67748e!important;
    font-size: 13px!important;
    margin-top: 0 !important;
    margin-right: 10px;
  }
  .route-indicators .green-color svg{
    fill: green;
    font-size: 12px;
  }
  .route-indicators .red-color svg{
    fill: red;
    font-size: 12px;
  }
  .trail-linechart .recharts-legend-item.legend-item-0 svg path {
    d: path('M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z') !important;
    fill: #6E26FA;
}
.trail-linechart .recharts-legend-item.legend-item-1 svg path {
  d: path('M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z') !important;
  fill: #8AC37D;
}
.trail-linechart .recharts-legend-item .recharts-legend-item-text{
  color: #554F60 !important;
  font-size: 12px;
}
.trail-linechart .recharts-cartesian-axis-ticks tspan {
  font-size: 10px;
  color: #252F40;
  font-weight: 600;
}
.audio-controls{
  margin: 15px 0;
  cursor: pointer;
}
.audio-controls button{
  background: none;
  border: 1px solid #252F3F;
  color: #252F40;
  border-radius: 3px;
  padding: 2px;
  margin-left: 15px;
  min-width: 38px;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 14px;
}
.audio-controls .MuiLinearProgress-root{
  position: relative;
  bottom: 32px;
  left: 180px;
  width: 333px;
}
.audio-controls .MuiLinearProgress-root.MuiLinearProgress-colorPrimary {
  background-color: #67748E !important;
  border: 1.4px solid #67748E;
}
.audio-controls .MuiLinearProgress-bar {
  background-color: #fff !important;
}
.leaflet-control-container .leaflet-routing-container-hide {
  display: none;
}

/* high chart css */
.highcharts-figure,
.highcharts-data-table table {
    min-width: 360px;
    max-width: 800px;
    margin: 1em auto;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}

/* chart css */
.trail-linechart .highcharts-navigator-xaxis, .trail-linechart .highcharts-navigator-series{
  display: none;
}
.trail-linechart  .highcharts-scrollbar *::-webkit-scrollbar-track {
  background: #EBEBFD;
}

.trail-linechart .highcharts-scrollbar *::-webkit-scrollbar-thumb {
  background-color: #4646F2;
  border-radius: 50px;
}

.trail-linechart .holder {
  width:640px;
  height:360;
  margin: 0 auto;
  margin-bottom:10px;
}

#pbr {
  width:100%;
}

.highcharts-figure,
.highcharts-data-table table {
  min-width: 360px;
  max-width: 800px;
  margin: 1em auto;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

/* audio controls */
input[type="range"] {
  height: 5px;
  -webkit-appearance: none;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 8px;
  background: #3b7677;
  transition: background 0.2s ease;
  cursor: pointer;
}

.audio-player {
  max-width: 350px;
  padding: 12px 24px;
  border-radius: 20px;
  margin: auto;
  color: var(--white);
}

.audio-controls-next {
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin: 0 auto 15px;
}

.audio-controls-next .prev svg,
.audio-controls-next .next svg {
  width: 18px;
  height: 18px;
}
.audio-controls-next button {
  background: none;
  border: none;
  cursor: pointer;
}
.track-info button.onex,  .track-info .onex{
  background: none;
  border: 1px solid #252F3F;
  color: #252F40;
  border-radius: 3px;
  padding: 2px;
  height: 22px;
  /* min-width: 38px; */
  font-size: 12px;
  font-weight: 600;
  margin-top: -10px;
  margin-left: 12px;
}
.track-info .onex:focus{
  outline: none;
}
.map-confirm-modal .MuiPaper-root{
  width: 350px;
  border-radius: 2px;
}
.map-confirm-modal .MuiPaper-root h2{
  padding-bottom: 10px;
}
.map-confirm-modal .MuiPaper-root p{
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}
.map-confirm-modal .MuiPaper-root button{
  font-size: 12px;
  font-weight: 500;
  padding: 10px;
  height: 35px;
  line-height: 22px;
}
.map-confirm-modal .MuiPaper-root .MuiDialogContent-root{
  padding-bottom: 6px;
}
.map-confirm-modal .MuiPaper-root .MuiDialogActions-root{
  justify-content: center;
  padding-bottom: 20px;
}
.audio-controls-next .play svg,
.audio-controls-next .pause svg {
  height: 16px;
  width: 16px;
}



.color-backdrop {
  background: linear-gradient(45deg, var(--active-color) 20%, transparent 100%)
    no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.color-backdrop.playing {
  animation: colorChange 20s alternate infinite;
}

@keyframes colorChange {
  from {
    filter: hue-rotate(0deg);
  }
  to {
    filter: hue-rotate(360deg);
  }
}

@media only screen and (min-width: 1200px) and (max-width:2000px){
  .maplibregl-map{
    height: 75vh !important;
  }
  /* .maplibregl-map canvas{
    height: 452px !important;
  } */
}
@media (min-width: 2000px){
  .maplibregl-map{
    height: 76vh !important;
  }
  /* .maplibregl-map canvas{
    height: 764px !important;
  } */
  .trail-page .MuiPaper-root{
    height: 75vh !important;
  }
}

.trail-linechart .highcharts-container {
  height: 280px !important;
}
.trail-linechart .highcharts-container .highcharts-root{
  height: 280px !important;
}


/* map layers-controls css */
.map-control{
  top: 30% !important;
}
.expand-map-control .map-layers{
  margin-top: -7px !important;
}
.expand-map-control .map-layers a svg{
  width: 1.5rem;
  margin-top: 7px;
}
.expand-map-control li:last-child a {
  border-radius: 0px 0px 6px 6px;
}
/* .expand-map-control .map-layers a:first-child{
  border-radius: 0px 0px 6px 6px !important;
} */
.expand-map-control #map-layer{
  position: absolute;
  right: -10px;
  top: -120px;
  bottom: 0;
  display: none;
  list-style: none;
  border: 1px solid #ddd;
  padding: 0;
}
.expand-map-control #map-layer li {
  float: right;
  border-bottom: 1px solid #ddd;
  padding: 0;
  height: 60px;
}
.expand-map-control #map-layer li:last-child {
  border-bottom: 0;
}
.expand-map-control #map-layer li a {
  font-size: 14px !important;
  width: 150px !important;
  display: block;
  height: 60.5px;
  padding: 5px 5px 5px 2px;
  background: #495763 !important;
  border-bottom: 1px solid #f1eaea;
  border-radius: 0 !important;
}
.expand-map-control #map-layer li a p {
  color: #fff;
  font-size: 12px;
  text-align: left !important;
  line-height: 54px;
  font-weight: 600;
}
.expand-map-control #map-layer li a .layer-item-img {
  width: 55px;
  height: 55px;
  float: left;
  margin-right: 10px;
  padding: 0 5px;
  overflow: hidden;
  position: relative;
}
.expand-map-control #map-layer li a .layer-item-img img.mode-img {
  border-radius: 10px;
  width: 50px !important;
}
.expand-map-control #map-layer li a .layer-item-img img.styleCheck {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  position: absolute;
  right: 0;
  bottom: 0;
  display: none;
}
.expand-map-control #map-layer #layer-close{
  position: absolute;
  right: 2px;
  color: #8f8f8f;
  top: 2px;
  font-size: 13px;
  font-weight: 600;
  text-transform: lowercase;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #f5f5f5;
  padding: 0px 2.5px;
  width: 14px;
  height: 14px;
  line-height: 10px;
  background: #fff;
}
.expand-map-control #map-layer #layer-close:hover{
  background: transparent;
  color: #fff;
}
.mapboxgl-ctrl > button:hover {
  background-color: #ddd !important;
}
.mapLayerSidePanelMain{
  display: none;
}
.mapLayerSidePanelMain.customPanelMain .mapLayerSidePanel {
  right: 0px;
  transition: all .5s ease;
}
.mapLayerSidePanel {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: -350px;
  /* overflow: scroll; */
  width: 330px;
  height: 100%;
  color: #212121;
  font-family: 'Montserrat Light', "Helvetica Neue", Arial, sans-serif;
  transition: all .5s ease;
  background-color: #fff;
  -webkit-box-shadow: 0 3px 4px 2px rgb(0 0 0 / 23%);
  -moz-box-shadow: 0 3px 4px 2px rgba(0,0,0,0.23);
  box-shadow: 0 3px 4px 2px rgb(0 0 0 / 23%);
}
.mapLayerHead {
  border-bottom: 1px solid #ddd;
  padding: 10px 15px;
}
.mapLayerHead h2 {
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 700;
}
.mapLayerHead .mapLayerCLose {
  float: right;
  color: #757575;
  display: block;
  margin-top: -32px;
}
.mapStyleSec {
  padding: 10px 15px 0;
  border-bottom: 2px solid #f3f3f3;
}
.mapStyleSec h3 {
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: 700;
}
.mapStyleList {
  list-style: none;
  padding: 0;
  text-align: center;
}
.mapStyleList li {
  width: 28%;
  margin: 0 7px;
  vertical-align: top;
  display: inline-block;
}
.mapStyleList li .mapStyleBlock {
  display: block;
  text-align: center;
}
.mapStyleList li .mapStyleBlock .mapStyleImg {
  height: 58px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}
.mapStyleList li .mapStyleBlock .mapStyleImg img.styleCheck {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  position: absolute;
  right: 0;
  bottom: 0;
  display: none;
}
.mapStyleList li .mapStyleBlock.active p {
  color: #212121;
  font-weight: 700;
}

.mapStyleList li .mapStyleBlock p {
  color: #757575;
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 12px;
}

button.maplibregl-ctrl-search {
  background-size: 25px 25px !important;
  background-repeat: no-repeat !important;
  border-radius: 4px !important;
  background-position: center !important;
  background-image: url("https://maps.mapmyindia.com/images/srarch1.png") !important;
}
.map-search-box{
  display: flex;
}
#mapsearch{
  display: none;
}
.map-search-box input[type=text] {
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  background: #fff;
  border: 2px solid #ddd;
  background-clip: padding-box;
  height: 37px;
  padding: 6px;
}
.map-search-box input[type=text]:focus {
  box-shadow: none;
  outline: none;
}
.search-close{
  position: absolute;
  right: 36px;
  top: 8px;
  background: none;
  font-weight: 500;
  font-size: 10px;
}

@media only screen and (min-width: 1200px) and (max-width:1600px)
{
  .trail-page .play-trail-map .MuiPaper-root{
    height: 65vh;
  }
}