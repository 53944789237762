.right-box .Card-Icons.one{
    border:none !important;
}
.right-box .Card-Icons{
    margin-top: -38px !important;
    border-color: blue !important;
    padding: 10px 0 7px 8px !important;
    width: 20px !important;
}
.right-box .Card-Icons .MuiButton-startIcon img{
  height: 15.5px;
}
.right-box .Card-Icons.map{
  top: -4px;
}
.right-box .Card-Icons .MuiSvgIcon-root{
    fill: blue !important;
    width: 0.8rem !important;
}
.right-box .Card-Icons{
  margin-right: 2px !important;
}
.right-box .Card-Icons .MuiButton-startIcon{
  margin-right: 0 !important;
}
.right-box .Card-Icons.MuiButton-root:hover {
  border: none !important;
}
.right-box{
    position: relative;
    left: 10px;
    top: 8px !important;
    display: flex;
    flex-direction: column;
}
.right-box .MuiCircularProgress-root[title="Idle Vehicle"].color-change{
  color: #FA9826;
}
.right-box .MuiCircularProgress-root[title="Other Vehicle"].color-change{
  color: #530ad1;
}
.right-box .MuiCircularProgress-root[title="Inactive Vehicle"].color-change{
  color: #000;
}
.right-box .MuiCircularProgress-root[title="GPS Removed"].color-change,
.right-box .MuiCircularProgress-root[title="Disabled Vehicles"].color-change{
  /* color: #4695F2; */
  color: #c0c0c0;
}
.ongoing-left .MuiGrid-item.pl-0{
  padding: 16px 0 !important;
}
.ongoing-left .MuiGrid-item.pr-0{
  padding-right: 0!important;
}
.ongoing-left .MuiGrid-item.ps-0{
  padding-left: 0.5rem!important;
}
.ongoing-left .css-96uuyl {
  width: 100% !important;
}
.ongoing-left .search-icon{
  right: 0 !important;
}
.ongoing-left .search-field{
  width: 230px;
}
.right-box .MuiCircularProgress-root[title="Stopped Vehicle"].color-change{
  color: #F25555;
}
.right-box .MuiCircularProgress-root[title="Moving Vehicle"].color-change{
  color: #63CE78;
}
.right-box .MuiCircularProgress-root svg circle {
  stroke-width:4.5;
}
.right-box .MuiCircularProgress-root svg circle{
  r:19;
}
.right-box .MuiCircularProgress-root{
  width: 63px !important;
}
.right-box .MuiTypography-root{
  color: #67748E;
  font-size: 12px;
  font-weight: bold;
  margin-right: -12px;
  margin-bottom: -4px;
}
.left-box p[status="Stopped"]{
  color: #F25555 !important;
}
.left-box p[status="Idle"]{
  color: #FA9826 !important;
}
.left-box p[status="other"]{
  color: #EFD54B!important;
}
.left-box p[status="Inactive"]{
  color: #000 !important;
}
.left-box p[status="Running"]{
  color: #63CE78 !important;
}
.left-box p[status="gpsremoved"], .left-box p[status="gps_removed"]{
  color: #c0c0c0 !important;
}
.left-box p{
    font-size: 12px !important;
    text-transform: capitalize;
    font-weight: 600;
}
.left-box h2.MuiTypography-body1{
    font-size: 14px !important;
    font-weight: bold !important;
    white-space: nowrap;
    color: #252F40;
}
.left-box{
    width: 90px;
}
.right-box .css-rmz4vk{
    margin-top: 1px !important;
    padding-right: 16px;
    position: relative;
    right: 20px;
    bottom: 8px;
}
.left-box h2{
    margin-bottom: 8px !important;
    font-weight: bold;
}

.right-text{
  position: absolute;
  right: 15px;
  padding: 0px 7px;
  border-right: 7px solid #4646F2;
  border-radius: 2px;
  bottom: -3px;
}
.right-text h2{
  font-weight: bold;
  font-size: 25px;
  color: #252F40;
}
.right-text p{
  color:#67748E;
  font-size: 14px;
  font-weight: 600;
}
.ongoing-left h2{
    font-size: 15px !important;
    font-weight: bold !important;
    margin-bottom: 15px !important;
    color: #252F40;
}
.ongoing-left input[type="text"],.ongoing-left .MuiInputBase-root .MuiInputBase-input,
.ongoing-left .MuiOutlinedInput-root,.ongoing-left .MuiFormControl-root{
    width: 100% !important;
    margin: 0;
}
.ongoing-left input[type="date"]{
  width: 99px !important;
}
.ongoing-left .MuiSelect-select{
    padding: 8px !important;
}
.ongoing-left .MuiInputLabel-root{
  top: -8px !important;
  margin-right: 20px;
  left: -6px;
  padding: 3px 1px;
  font-size: 12px;
  color: #252F40;
  text-transform: capitalize;
}
.ongoing-left .MuiInputBase-input{
    padding: 8px !important;
}
.ongoing-left .MuiFormControl-root{
    margin-left: 6px !important;
}
.ongoing-left .MuiSelect-select{
    padding: 6px !important;
}
.ongoing-left .MuiFilledInput-root::after{
  border-bottom: none !important;
}
.ongoing-left .serach-field{
  margin-right: 4px !important;
}
.ongoing-left .serach-field .MuiSvgIcon-root,
.ongoing-left .search-icon svg{
  fill: #fff !important;
  width: 0.8em;
}
.ongoing-left .MuiBox-root h2{
    margin-bottom: 0px !important;
    color: #252F40 !important;
    font-weight: bold !important;
    font-size: 16px !important;
}
.ongoing-left .MuiBox-root p{
  color: #252F40;
  font-size: 12px;
}
.ongoing-left .css-1iz4iln{
    height: 36px !important;
    width: 33px;
    bottom: 1px;
}

.ongoing-left .MuiOutlinedInput-notchedOutline{
    border: none !important;
}
.ongoing-left .date-range .react-datepicker-wrapper input {
  padding: 19px 6px 15px!important;
}
.ongoing-left .date-range .MuiFormControl-root {
  background: transparent!important;
}
.ongoing-left .date-range .cal-icon {
  right: 2px!important;
  top: 24px;
}
.ongoing-left .date-range .MuiFormLabel-root {
  font-size: 14px;
}
.ongoing-left  .date-range .MuiFormControl-root{
  margin-left: 0 !important;
}
.ongoing-left  .date-range .react-datepicker-popper .react-datepicker{
  display: flex;
  flex-direction: row;
}
.ongoing-left  .date-range .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  top: -0.5px !important;
}
.Panic-left h2{
    font-size: 15px !important;
    font-weight: bold !important;
    margin-bottom: 15px !important;
    color: #252F40 !important;
}
.Panic-left .MuiBox-root h2{
    font-size: 22px !important;
    font-weight: bold !important;
    color: #252F40 !important;
    text-align: right;
    margin-bottom: 8px !important;
}
.Panic-left .MuiBox-root p{
text-align: right;
color: #67748E;
font-size: 14px;
}
.Panic-left .MuiAvatar-root{
    position: relative;
    bottom: 6px;
    background-color: transparent;
}
.Panic-left .MuiAvatar-root img{
    width: 27px;
    height: 27px;
    object-fit: fill;
}
.Panic-left .MuiAvatar-root.img-size img{
    width: 22px;
    height: 22px;
}
.Panic-left .panic-box{
    border-right:1.2px solid #707070;
    justify-content: center;
}
.Panic-left .MuiGrid-item {
  padding-left: 0px !important;
}
.Panic-left{
  padding: 5px 0 !important;
}

.Panic-left button {
    padding-left: 14px !important;
    margin-top: 8px;
    float: right;
    padding: 6px !important;
}
.Panic-left button span{
  margin-right: 0px !important;
}
.Panic-left button:hover{
    border: 1px solid #4646F2 !important;
    color: #252F40 !important;
} 
.left-chart{
  width: 50%;
}
.left-chart .MuiPaper-root{
    margin-right: 0.5rem;
    padding: 15px;
}
.left-chart .Target-root{
    height: 258px !important;
    width: 100% !important;
}
.left-chart .MuiTypography-root{
    color: #252F40;
}
.left-chart .distance-paper h4{
  font-size: 15px;
  font-weight: bold;
  white-space: nowrap;
  margin-bottom: 2px;
}
.left-chart .distance-paper *::-webkit-scrollbar {
  width: 7px;
}
.left-chart .distance-paper *::-webkit-scrollbar-thumb {
  background-color: #4646F2;
  border-radius: 50px;
}
.left-chart .panic-messages{
  background: #F9F9F9 !important;
  margin: 10px 0;
  border-radius: 8px;
  margin-right: 7px !important;
}
.left-chart .distance-paper .message-count{
  padding: 10px;
  color: #252F40;
  font-size: 12px;
  font-weight: 400;
}
.left-chart .distance-paper .panic-messages h4{
  margin-bottom: 1px;
  text-transform: capitalize;
  font-size: 14px;
}
.left-chart .distance-paper .panic-messages .panic-mloc{
  color: #252F40;
  font-size: 11px;
  text-align: left;
  font-weight: 600;
  margin-top: -7px;
  margin-bottom: 1px;
}
.left-chart .distance-paper .panic-messages .panic-mloc span{
  margin-right: 5px;
}

.left-chart .distance-paper .panic-messages .Card-Icons:hover{
  border: none !important;
  color: transparent !important;
}
.left-chart .distance-paper .panic-messages .Card-Icons svg{
  fill: #FA9826 !important;
  width: 0.9rem !important;
  height: 0.9rem !important;
}
.left-chart .distance-paper .panic-messages p{
  color: #554F60;
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 0px;
}
.left-chart .distance-paper .viewall{
    float: right;
    margin-right: 10px;
    font-size: 13px;
    font-weight: 400;
    text-decoration: none;
    color: #4646f2;
}
.panic-Mapview .modal-box{
  padding: 0 !important;
  border: none !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.communicate-right h4{
  color: #252F40 !important;
  font-size: 15px;
  font-weight: bold;
}
.left-chart .pie-right h4{
  color: #252F40 !important;
  font-size: 15px;
  font-weight: bold;
}
.left-chart .MuiInputBase-input {
padding: 10px !important;
}

.ongoing-left *::-webkit-scrollbar {
  width: 7px;
}

.ongoing-left *::-webkit-scrollbar-thumb {
  background-color: #4646F2;
  border-radius: 50px;
}
.ong-box{
  height: 416px;
  overflow-y: auto;
  overflow-x: hidden;
}
.ongoing-left .ong-box{
  height: 400px;
}
.ongoing-progress .progress {
    position: relative;
    padding: 0;
    margin: 0 0;
    list-style: none;
    display: flex;
    height: 30px;
  }
  
.ongoing-progress .progress__item {
    position: relative;
    counter-increment: list;
    padding-left: 0.5rem;
  }
.ongoing-progress .progress__item:before {
      content: "";
      position: absolute;
      left: 1.65rem;
      top: 9px;
      width: 70px;
      border-top: 1.5px dotted green;
    }
.ongoing-progress .progress__item:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0.5rem;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #aeaeae;
      color: #fff;
      font-weight: 600;
      font-size: 13px;
      line-height: 1rem;
      text-align: center;
      border: 1px dotted #aeaeae;
    }
   
.ongoing-progress .progress__item:last-child:before {
        border: none;
    }
   
.ongoing-progress .progress__item.progress__item--completed:after  {
        opacity: 0.6;
        content: "\2713";
        font-weight: 600;
        background: green;
        color: #fff;
    }
   
.ongoing-progress .progress__item.progress__item--active:after  {
        background: #aeaeae;
        color: #fff;
  }
  
  /* .rs-popover[class*='placement-left'] {
    margin-left: -8px;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  .rs-popover[class*='placement-left'].rs-anim-in {
    -webkit-transform: translate(-2px, 0);
            transform: translate(-2px, 0);
  }
  .rs-popover[class*='placement-left'] > .rs-popover-arrow {
    right: -6px;
    margin-top: -6px;
  }
  .rs-popover[class*='placement-left'] > .rs-popover-arrow::after {
    right: -6px;
    margin-top: -6px;
    border-width: 6px 0 6px 6px;
    border-left-color: #fff;
    border-left-color: var(--rs-bg-overlay);
  } */

.ongoing-stepper .MuiStepLabel-iconContainer svg{
  width: 0.6em !important;
  height: 0.6em !important;
  color: #B9B9B9;
}
.ongoing-stepper .MuiStepLabel-iconContainer .MuiStepIcon-root.Mui-active {
  color: #63CE78 !important;
}
.ongoing-stepper .MuiStepLabel-iconContainer svg .MuiStepIcon-text{
  display: none !important;
}
.ongoing-stepper .Mui-completed .MuiStepConnector-line{
  border-color: #63CE78 !important;
}
.ongoing-stepper .MuiStepConnector-line {
  border-color: #B9B9B9 !important;
  border-top-width: 1.35px !important;
  border-top-style:dashed !important;
}

.ongoing-stepper .MuiStepLabel-iconContainer{
  padding-right: 0 !important;
}

.ongoing-stepper .MuiStep-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.ongoing-stepper{
  padding-left: 8px !important;
  padding-right: 8px;
}
.ongoing-progress .progress__title {
    padding: 0.4rem 0 0.5rem;
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    width: 120px;

  }
  
.ongoing-progress .progress__info {
    font-size: 13px;
    position: relative;
    left: 8rem;
    text-align: left;
    top: -41px;
    width: 80px;
  }
  .ongoing-progress{
    background: #F9F9F9 !important;
    margin: 10px 0;
    border-radius: 8px;
    margin-right: 7px !important;
  }
  .ongoing-progress h4{
    color:#252F40 !important;
    margin-bottom: 0 !important;
    font-weight: bold;
    font-size: 13px;
    white-space: nowrap;
    text-transform: capitalize;
  }
  .ongoing-progress  h4 span{
    font-size: 11px !important;
    color: rgba(0, 0, 0, 0.6) !important;
  }
  .ongoing-progress .MuiButton-root{
    float: right;
  }
  .ongoing-progress .box-P p{
    padding-left: 0 !important;
    margin-top: 2px !important;
    text-transform: lowercase;
  }
  .ongoing-progress .box-P p::first-letter{
    text-transform: uppercase;
  }
  .ongoing-progress .MuiBox-root p.MuiTypography-root{
    padding-left: 10px;
    margin-top: -11px;
    margin-bottom: 15px;
    font-size: 13px;
  }
  .location-box h4{
    font-size: 14px !important;
    margin-bottom: 15px !important;
    margin-left: 10px;
    text-transform: lowercase;
    margin-right: 10px;
  }
  .location-box h4::first-letter{
    text-transform: uppercase;
  }
  .ongoing-progress  .location-box  .css-adfmws-MuiTypography-root{
    /* padding-left: 0 !important; */
  }
  .ongoing-progress .MuiButton-root:hover{
    border: none !important;
  }
  .serach-field  .MuiInputBase-root{
    width: 100% !important;
  }
  .date-range .MuiFormControl-root{
    width: 100%!important;
    background: #f0f0ff;
    margin-left: 13px !important;
    border-radius: 4px;
    
  }
  .route-sec .MuiToolbar-root.route-assign .date-range .MuiFormControl-root{
    padding: 5px 8px;
    margin-left: 6px !important;
  }
  .route-sec .MuiToolbar-root.route-assign .date-range .cal-icon{
    right: 2px;
  }
  .date-range .cal-icon{
    position: absolute;
    right: -5px;
    z-index: 0;
    padding: 8px 0 !important;
  }
  .date-range .cal-icon:hover,.date-range .cal-icon:focus{
    background-color: transparent !important;
    color: transparent;
  }
  .date-range .cal-icon svg{
    fill:#67748E;
    width: 1rem !important;
    height: 1rem !important;
  }
  .date-range .react-datepicker-wrapper input{
    width: 100% !important;
    background: #f0f0ff;
    height: 25px;
    border: none;
    border-radius: 4px;
    padding: 6px;
    color: #252F40;
    font-size: 12px;
  }
  .date-range .react-datepicker-wrapper ::placeholder {
    color: #252F40;
    font-size: 12px;
  }
  .date-range .react-datepicker__close-icon{
    right: 6px !important;
    display: none;
  }
  .date-range .react-datepicker__close-icon::after{
    width: 8px !important;
    height: 8px !important;
    font-size: 8px;
    font-weight: bold;
  }
  .react-datepicker-popper{
    z-index: 99;
  }
  .date-range .react-datepicker-wrapper input:focus{
    outline: none !important;
  }
  .communicate-right{
    display: flex !important;
    justify-content: space-between !important;
  }
  .communicate-right svg {
    cursor: pointer;
  }
  .communicate-right p{
    color: #67748E !important;
    font-size: 13px !important;
  }
  .communicate-right .red-color{
    margin-left: 100px !important;
  }
  .communicate-right .red-color,.communicate-right .blue-color{
    font-size: 12px !important;
  }
  .communicate-right .red-color .MuiSvgIcon-root{
    fill:#F25555 !important;
    font-size: 12px;
  }
  .communicate-right .blue-color .MuiSvgIcon-root{
    fill: #4646F2 !important;
    font-size: 12px;
  }
  .left-chart .recharts-layer tspan{
    font-size: 12px !important;
    color: #67748E !important;
  }
 .left-chart .recharts-legend-wrapper{
    display: none;
  }
  .left-chart .distance-paper{
    width: 100% !important;
    margin-top: 0.5rem;
    margin-right: 0 !important;
    margin-bottom: 0;
    height: 483px;
  }
  .pie-chart-view .recharts-wrapper{
    bottom: 90px !important;
    right: 30px;
  }
  .pie-right{
    display: flex !important;
    justify-content: space-between !important;
  }
  .pie-right .red-color{
    margin-left: 100px !important;
  }
  .non-commun-barchart .recharts-cartesian-axis-tick-line,.non-commun-barchart .recharts-yAxis .recharts-cartesian-axis-line{
    display: none;
  }
  
  .pie-right .red-color,.communicate-right .blue-color{
    font-size: 12px !important;
  }
  .pie-right .red-color .MuiSvgIcon-root{
    fill:#F25555 !important;
    font-size: 14px;
  }
  .pie-right .blue-color .MuiSvgIcon-root{
    fill: #4646F2 !important;
    font-size: 14px;
  }
 
  .pie-chart-view{
    height: 286px !important;
  }
  .pie-chart-view .pie-wrap-det .MuiTypography-root,.pie-chart-view .pie-wrap-det .MuiSvgIcon-root{
    font-size: 14px !important;
  }
  .pie-chart-view .pie-wrap-det .MuiTypography-root span{
    font-weight: 600 !important;
    color: #252F40;
    margin-left: 8px;
    font-size: 14px;
  }
  .pie-chart-view .pie-wrap-det p{
    margin-right: 5px;
    margin-bottom: 5px;
    white-space: nowrap;
    color: #67748E !important;
  }
  .pie-chart-view .pie-wrap-det .lightb-color .MuiSvgIcon-root{
    fill:#55B9ED !important;
  }
  .pie-chart-view .pie-wrap-det .violet-color .MuiSvgIcon-root{
    fill:#B894FB !important;
  }
  .pie-chart-view .pie-wrap-det .navyb-color .MuiSvgIcon-root{
    fill: #1F78B4 !important;
  }
  .pie-chart-view .pie-wrap-det .orange-color .MuiSvgIcon-root{
    fill: #F0AB5B !important;
  }
  .pie-chart-view .pie-wrap-det .pink-color .MuiSvgIcon-root{
    fill: #FA9F9F !important;
  }
  .pie-chart-view .recharts-wrapper{
    width: 210px !important;
  }
  .pie-chart-view .pie-wrap-det{
    margin-top: 30px;
    height: 100%;
  }
  .pie-chart-view .pie-wrap-det .show-btn{
    padding: 8px !important;
  }
  

 .summary-toolbar .vts-form .filter-field[data-source="q"] .MuiFormControl-root.ra-input {
    top: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    height: 38px;
}
.summary-toolbar .vts-form .filter-field[data-source="q"] .MuiFormControl-root.ra-input .MuiFilledInput-input{
  padding-top: 11px !important;
}
.summary-toolbar .vts-form .date-range .react-datepicker__navigation-icon{
  top: 6px !important;
}
.summary-toolbar .vts-form .date-range .react-datepicker__navigation--previous {
  left: 12px !important;
}
.summary-toolbar .vts-form .date-range .react-datepicker__navigation--next{
  right: 12px !important;
}
.summary-toolbar .vts-form .date-range{
  height: 38px;
}
.summary-toolbar .vts-form .date-range .MuiFormControl-root{
  margin: 0px 0px!important;
  padding: 5px;
  height: 38px;
}
.summary-toolbar .vts-form .date-range .cal-icon{
  right: 4px !important;
  bottom: 4px;
}
.summary-toolbar .vts-form .filter-field[data-source="group_id"] .MuiFormControl-root.ra-input {
  top: 2px !important;
}
.summary-toolbar .vts-form .filter-field[data-source="group_id"] .ra-input .MuiFilledInput-input,.summary-toolbar .vts-form .filter-field[data-source="group_id"] .MuiFormControl-root .MuiFilledInput-root {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.summary-toolbar .vts-form .filter-field[data-source="group_id"] .ra-input .MuiFilledInput-input{
  height: 36px;
  padding-left: 8px !important;
}
.summary-toolbar .vts-form .sel-group {
  width: 200px;
}
.no-message{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.dashboard .card-title{
    padding: 16px 16px 6px;
    display: flex;
    align-items: center;
}
.dashboard .card-title h2{
  letter-spacing: 0px;
  color: #252F40;
  font-size: 15px;
  font-weight: 600;
}
.dashboard .card-title .img-icon{
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.dashboard .card-title .img-icon img{
  height: 22px;
}
.dashboard .card-divider{
  border-right: 1px solid #707070;
  height: 45px;
  margin: 0 20px;
}
.dashboard .left-box{
  width: auto!important;
}
.dashboard .card-title .sel-group{
  position: absolute;
  right: 15px;
  width: 220px;
}
.dashboard .card-title.trip .sel-group{
  right: 85px !important;
}
.dashboard .card-title.trip .show-btn{
  position: absolute;
  right: 15px;
}
.dashboard .card-title .sel-group .css-1nmdiq5-menu,
.dashboard .card-title .sel-group .css-1nmdiq5-menu .listbox{
    font-size: 12px !important;
}
.dashboard .card-title .sel-group .css-23lq6y-menu{
  overflow: visible;
  position: relative;
  z-index: 9999;
}
.dashboard .card-title .sel-group .css-1xc3v61-indicatorContainer:first-child{
  display: none;
}
.dashboard .const-table{
  width: 100%;
  vertical-align: middle;
}
.dashboard .const-table thead{
  background: #F5F6FA;
}
.dashboard .const-table thead th{
  letter-spacing: 0px;
  color: #252F40;
  font-size: 12px;
  font-weight: 600;
  padding: 10px;
  text-align: left;
}
.dashboard .const-table thead th:first-child,
.dashboard .const-table tbody td:first-child{
  border-radius: 4px 0 0 4px;
}
.dashboard .const-table thead th:last-child,
.dashboard .const-table tbody td:last-child{
  border-radius: 0px 4px 4px 0px;
}
.dashboard .const-table tbody tr:nth-child(even){
  background: #EDF0F8; 
}
.dashboard .const-table tbody td{
  letter-spacing: 0px;
  color: #252F40;
  font-size: 12px;
  font-weight: 400;
  padding: 10px;
}
  @media (min-width: 1201px) and (max-width: 1400px){
  /* .left-chart .distance-paper {
    width: 96.2% !important;
  } */
  .right-box{
    left: -4px !important;
  }
  .right-box .css-15p9n5u{
    margin-right: 20px;
  }
}
@media only screen and (min-width: 992px) and (max-width:1200px) {
  .left-chart .distance-paper .viewall{
    position: relative;
    top: 22px;
  }
  .right-box .css-rmz4vk{
    right: 28px;
  }
  .right-box .Card-Icons .MuiSvgIcon-root{
    width: 0.8rem !important;
  }
  .left-box h2.MuiTypography-body1{
    font-size: 10px !important;
  }
  .right-box .MuiBox-root:first-child{
    position: relative;
    right: 26px;
  }
  .right-box .Card-Icons .MuiButton-startIcon img {
    height: 11.5px;
  }
  .right-box .MuiCircularProgress-root {
    width: 54px !important;
}
.right-box .css-rmz4vk{
  right: 5px !important;
}
.right-box .css-15p9n5u{
  margin-right: 20px;
}
}

@media only screen and (min-width: 768px) and (max-width:991px) {
  .left-chart .distance-paper .viewall{
    position: relative;
    top: 22px;
  }
  .right-box {
    left: 0 !important;
  }
  .right-box .css-rmz4vk{
    right: 28px;
  }
  .right-box .Card-Icons .MuiSvgIcon-root{
    width: 0.8rem !important;
  }
  .left-box h2.MuiTypography-body1{
    font-size: 10px !important;
  }
  .right-box .MuiBox-root:first-child{
    position: relative;
    right: 26px;
  }
  .right-box .Card-Icons .MuiButton-startIcon img {
    height: 11.5px;
  }
  .right-box .MuiCircularProgress-root {
    width: 48px !important;
}
.right-box .Card-Icons.one{
  left: 9px;
}
}