    body{
      overflow-x: hidden;
      overflow-y: hidden;
    }
    .layout header .MuiToolbar-root{
      /* margin-right: 15px; */
      padding-right: 0 !important;
    }
    .RaSidebar-fixed{
      background-color: #fff;
      box-shadow: 0px 0px 3px #bdbdbd;
      width: 180px !important;
      /* position: absolute !important; */
      height: calc(100vh) !important;
      z-index: 10;
    }
    .css-zfn0io{
      width: 180px !important;
    }
    main .MuiDrawer-root .MuiPaper-root {
    width: 194px !important;
    }
    .Left-menu a.RaMenuItemLink-active{
      color: #4646F2 !important;
      border-right: 4px solid #4646F2;
      border-radius: 2px;
    }
    .Left-menu a.RaMenuItemLink-active svg{
        fill: #4646F2 !important;
      }
      .Left-menu a{
      font-size: 14px !important;
      text-decoration: none;
      }
      .css-bhp9pd-MuiPaper-root-MuiCard-root{
        background-color: transparent !important;
      }
      .RaDatagrid-table tbody .RaDatagrid-row:nth-child(even){
        background-color: #fff !important;
      }
      .RaDatagrid-table thead .RaDatagrid-headerCell{
        background-color: transparent !important;
        position: unset;
        overflow: hidden;
        white-space: nowrap;
        
      }
      .RaDatagrid-table thead tr{
        position: sticky;
        top: 0px;
        z-index: 2;
        background-color: #fff !important;
      }
      .RaDatagrid-table tr td,.RaDatagrid-table thead th{
        border-bottom: none !important;
      }
      .RaDatagrid-table tr td:first-child{
        border-radius: 5px 0 0px 8px;
      }
      .RaDatagrid-table tr td:last-child {
        border-radius: 0px 5px 5px 0px;
    }
    .RaDatagrid-table tbody tr td .MuiSvgIcon-root{
        font-size: 17px;
        position: relative;
    }
    .RaDatagrid-table tbody tr td span{
      margin-left: 5px;
      display: flex;
      align-items: center;
    }
    .RaDatagrid-table tbody tr td span img{
      height: 16px;
      margin-right: 8px;
    }
    .RaDatagrid-table tbody tr td{
      padding: 15px;
      white-space: nowrap;
    }
    /* User.css */

      .table-grid .RaDatagrid-clickableRow,.table-grid .RaDatagrid-row{
        height: 50px !important;
      }
      .table-grid thead .column-id span svg{
        fill: #000 !important;
      }
      .css-18ysy7u-MuiButtonBase-root-MuiCheckbox-root{
        color: #4646F2 !important;
      }
      
    .RaList-main .MuiToolbar-root button:first-child,.RaList-main .css-1or4sgo-MuiButtonBase-root-MuiButton-root-RaButton-root.MuiButton-sizeSmall:hover{
      background: #fff !important;
      border: 1px solid #4646F2 !important;
      color: #000 !important;
    }
    .MuiButton-startIcon{
      display: none !important;
    }
    .css-x807kr-MuiDrawer-docked-RaSidebar-root .MuiPaper-root{
      width: 200px !important;
    }
    .layout .RaLayout-appFrame {
        margin-top: 0 !important;
    }
    .css-1k7utw{
      width: auto !important;
    }
    header{
      box-shadow: none !important;
    }
    .RaLayout-content .MuiInputBase-root .MuiInputBase-input{
      background: #f0f0ff !important;
      /* padding-left: 8px; */
      border-radius: 5px !important;
      font-size: 12px !important;
    }
    .RaLayout-content .css-1iz4iln{
      z-index: 1 !important;
    }
    .RaLayout-content .MuiTable-root .MuiCheckbox-root .MuiSvgIcon-root{
      fill: #4646F2;
    }
    .css-96uuyl{
      margin-left: 0 !important;
    }
    .css-1iz4iln{
      position: absolute;
      right: -5px;
      padding: 0px 6px !important;
      background: #4646F2;
      border-radius: 8px;
      z-index: 1;
    }
    .css-1kcggdq-MuiInputBase-root .MuiInputBase-input{
      padding-left: 8px !important;
      color: #000;
      font-size: 12px;
      width: 220px !important;
      border-radius: 5px;
      background: #f0f0ff !important;
    }
    header.MuiPaper-root{
      background-color: #F5F6FA !important;
      position: fixed;
      top: 0px;
      z-index: 9;
      height: 52px;
    }
    header .header-title{
      left: 178px;
      position: relative !important;
    }
    header .header-account{
      /* margin-right: -25px; */
      /* left: calc(100% - 20%);
      position: absolute;
      width: 262px; */
    }
    .layout,.RaLayout-appFrame{
      display: -webkit-box !important;
      min-width: unset !important;
    }
    .RaLayout-appFrame{
      width: 100%;
    }
    .layout .RaLayout-content{
        background-color: #F5F6FA !important;
    }
    .RaLayout-contentWithSidebar{
        background-color: #F5F6FA !important;
        display: -webkit-box !important;
    }
    .RaLayout-contentWithSidebar .MuiDrawer-docked{
      height: calc(100vh - 0em) !important;
    }
    main .MuiDrawer-root{
      /* position: relative;
      bottom: 64px !important;
      height: calc(110vh) !important; */
    }
    header .MuiBox-root p{
      color: #000 !important;
      line-height: 42px;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
    }
    .MuiButtonBase-root svg{
      fill: #000;
      width: 1.1rem !important;
      height: 1.1rem !important;
    }
    .MuiToolbar-root .MuiTypography-root{
      color: #000 !important;
    }
    .header-title .MuiTypography-root{
      color: #252F40 !important;
      font-size: 24px;
      font-weight: bold;
    }
    .MuiBox-root .MuiIconButton-edgeEnd{
      padding: 6px  24px!important;
    }
    .MuiButtonBase-root .BaseBadge-badge{
      font-size: 8px;
      min-width: 3px;
      line-height: 1;
      padding: 8px 5px;
      height: 8px;
        }
    .RaLayout-content .MuiTabs-indicator{
        background-color: #4646F2 !important;
        border-radius: 8px 8px 0 0 !important;
        height:3.5px !important;
      }
  .RaLayout-content .MuiFilledInput-root:before{
        display: none !important;
      }
   .RaLayout-content .MuiFilledInput-input {
        padding-bottom: 4px !important;
        padding-top: 14px !important;
        border-radius: 5px !important;
      }
      .Mui-focused .css-1zuho2-MuiInputBase-input-MuiFilledInput-input {
        padding-top: 21px !important;
        padding-bottom: 4px !important;
      }
      .RaLayout-content .MuiFilledInput-root{
        border-radius: 5px !important;
      }
      .RaLayout-content .MuiInputLabel-root{
        line-height: 12px !important;
        z-index: 9;
      }
      .RaLayout-content .MuiInputLabel-root span{
        font-size: 14px !important;
      }
      .css-1iz4iln .css-i4bv87-MuiSvgIcon-root{
        fill: #fff !important;
      }
      .css-1hejy8i-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.MuiSelect-select{
        padding: 4px !important;
        padding-top: 11px !important;
      }
      .Left-menu li.MuiMenuItem-root {
      margin-left: -35px !important;
      font-weight: 600 !important;
      color: #000 !important;
      font-size: 14px !important;
      margin-bottom: 15px !important;
      margin-top: 18px !important;
      }
      .Left-menu li.MuiButtonBase-root.MuiMenuItem-root:focus::after{
        background-color: transparent !important;
      }
      .Left-menu li.MuiMenuItem-root:hover{
        background-color: transparent !important;
        cursor: inherit;
      }
      .MuiTabs-root .MuiButtonBase-root{
        text-transform: capitalize !important;
        font-weight: 600 !important;
      }
     .css-6929yj .MuiBox-root .MuiFormControl-root .MuiInputLabel-root{
        margin-top: -32px;
        margin-left: -10px;
      font-size: 14px;
      font-weight: 500;
      }
      .MuiBox-root .MuiFormControl-root .MuiFilledInput-root{
        border: 1px solid #f0f0ff;
        border-radius: 2px;
        background-color: transparent;
        border: none;
      }
      .MuiButton-startIcon{
        display: block !important;
      }
      .Card-Icons{
        background: none !important;
        box-shadow: none !important;
        border: 1px solid #7b7b9e !important;
        min-width: 20px !important;
        height: 20px !important;
        border-radius: 20px !important;
        padding: 13px 0 10px 8px !important;
        width: 25px;
        margin-right: 5px !important;
        /* margin-top: 20px !important; */
      }
      .Card-Icons .css-i4bv87-MuiSvgIcon-root{
        fill: #7b7b9e !important;
      }
      .Card-Icons .css-y6rp3m-MuiButton-startIcon{
        margin-right: 6px !important;
        line-height:  18px !important;
      }
      .Card-Title .Card-Icons{
        margin-top: 0px !important;
        margin-left: 15px !important;
        border: 1px solid #4646F2 !important;
      }
      .Card-Title .Card-Icons .MuiButton-startIcon .MuiSvgIcon-root{
        fill: #4646F2 !important;
      }
      .Card-Title .icon-css{
        top: 0 !important;
      }
      .RaDatagrid-tableWrapper .Card-Icons.MuiButton-root:hover {
        border: none !important;
      }
      .Map-div{
        margin-left: 20px !important;
        width: 100% !important;
        margin-bottom: 50px;
        background-color: #f5f6f7;
        padding: 1rem;
      }
      
      .left-Cards .css-1sypbpd{
        width: 250px !important;
      }
  
    .MuiTabs-root .MuiButtonBase-root:focus::after{
      background-color: transparent !important;
    }
    .layout .RaLayout-content{
      padding-right: 0px !important;
    }
    .RaDatagrid-table th, .RaDatagrid-table tbody tr td{
      padding-right: 10px !important;
      padding: 12px 10px !important;
    }
    .MuiButton-root:hover{
      border: 1px solid #4646F2 !important;
      color: #4646F2 !important;
    }
    header .MuiButtonBase-root:hover {
        background-color: transparent !important;
    }
    header .MuiButtonBase-root:hover .MuiSvgIcon-root{
        fill: #4646F2 !important;
    }
    .logo-bar{
        margin-bottom: 15px;
        padding: 8px 8px 0 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .logo-bar img{
        height: 46px !important;
        /* margin-left: calc(100% - 62%); */
    }
    .logo-bar h2{
      font-size: 13px;
      font-weight: 600;
      color: #252F40;
      margin-top: 6px;
      margin-bottom: 3px;
    }
    .RaDatagrid-tableWrapper {
      /* overflow: auto; */
    }
    .RaDatagrid-tableWrapper thead th span{
      font-weight: 600;
      font-size: 12px;
      color: #252F40;
    }
  #main-content{
    width: 1064px;
    margin-top: 60px;
  }
  .MuiToolbar-root button.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeSmall.MuiButton-textSizeSmall.MuiButtonBase-root.css-1x9tcuv-MuiButtonBase-root-MuiButton-root-RaButton-root-RaBulkDeleteWithUndoButton-root {
    display: none;
  }

  .tab-content{
    height: calc(100vh - 120px);
    /* overflow-y: auto;
    overflow-x: hidden; */
    overflow: auto;
  }
  .tab-top{
    padding-bottom: 15px;
  }
  .list-content{
    /* height: 385px; */
    height: 62vh;
    overflow: auto;
    /* overflow-x: hidden; */
    position: relative;
  }
  .map-content{
    height: calc(100vh - 150px);
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
  }
  .mapview-content{
    height: calc(100vh - 190px);
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
  }
  @media only screen and (min-width: 768px) and (max-width:1600px){
    /* .map-content{
      height: 510px !important;
    } */
    /* .list-content{
      height: 385px !important;
    } */
    /* .mapview-content{
      height: 430px !important;
    } */
    /* .tab-content{
      height: 480px;
    } */
  }

  .MuiTablePagination-toolbar .MuiTablePagination-select{
    padding-right: 25px !important;
  }
  .layout-disable{
    opacity: 0.3;
  }
  /* Account css */
  .Account-det .MuiInputLabel-root span{
    color: #67748E !important;
    font-weight: normal !important;
    font-size: 14px !important;
  }
  .Account-det label.Mui-focused{
    margin-top: -22px !important;
  }
  .Account-det .MuiInputLabel-root{
    margin-top: -34px !important;
    margin-left: -10px;
  }
  .Account-det .MuiBox-root .MuiFormControl-root .MuiFilledInput-input {
    background: #EDEDFF !important;
    font-size: 13px !important;
    border-radius: 5px;
    padding: 10px 14px;
  }
  .Account-det .Mui-focused .css-1zuho2-MuiInputBase-input-MuiFilledInput-input {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .Account-det .MuiBox-root .MuiFormControl-root .MuiFilledInput-root:before, 
  .Account-det .MuiBox-root .MuiFormControl-root .MuiFilledInput-root:after {
    border-bottom: none !important;
  }
  .Account-det h4 {
    font-size: 16px !important;
    font-weight: 600;
    color: #252F40;
  }
  .Account-det .action-icons{
    line-height: 24px;
    float: right;
    cursor: pointer;
  }
  .Account-det .action-icons img{
    height: 20px;
    margin-right: 6px
  }
  .Account-det .action-icons .MuiSvgIcon-root{
    margin-right: 4px;
  }
  .Account-det .MuiFormControl-root{
    width: 100%;
  }
  .Account-det .MuiOutlinedInput-root input, .Account-det .MuiFilledInput-root input{
    padding-top:10px !important;
    padding-bottom: 10px !important;
  }
  .Account-det .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline{
    border: none !important;
  }
  .Account-det{
    height: calc(100vh - 60px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  /* .Account-det .MuiFormHelperText-root{
    display: none;
  } */
  .Account-det .account-btn button{
    background: #FFFFFF !important;
    border: 1px solid #4646F2 !important;
    border-radius: 6px !important;
    text-transform: capitalize;
    color: #4646F2;
    width: 86px;
    height: 34px;
  }
  .Account-det .account-btn{
    float: right !important;
  }
  .Account-det .account-btn .update-btn{
    background: #4646F2 !important;
    color: #fff !important;
  }

  /* Notification */
  .notification-heading h4{
    color: #252F40;
    font-size: 14px !important;
    font-weight: 600 !important;
    margin: 0;
    padding-left: 4px;
  }
  .notification-heading h4.pull-right{
    color: #67748E;
    font-size: 14px;
    font-weight: normal !important;
    float: right !important;
    margin-right: 20px;
  }
  .notification-det .item-info,.notification-det .item-date{
    margin: 6px 0;
  }
  .notification-det .item-info{
    color: #554F60;
    font-size: 14px;
  }
  .notification-det .item-date{
    color: #67748E;
    font-size: 12px !important;
    font-weight: 600;
  }
  .notification-det img{
    height: 38px;
  }
  .notification-det .notify-title{
    color: #252F40;
    font-weight: 600;
    font-size: 16px;
  }
  .menu-popup .MuiPaper-root{
    top: 48px !important;
    left: calc(100% - 198px) !important;
  }
  .menu-popup .MuiPaper-root .MuiList-root svg{
    margin-right: 5px;
  }
  .menu-popup .MuiPaper-root li.MuiMenuItem-root{
    padding-left: 12px !important;
  }
  .vts-form .ra-input label[data-shrink="true"]{
    display: none;
  }
  .vts-form .ra-input .Mui-focused input,  .vts-form .ra-input .MuiFilledInput-input{
    padding-top: 10px !important;
    padding-bottom: 8px !important;
  }
  .vts-form .ra-input .MuiFilledInput-root:after{
    border-bottom: none !important;
  }
  @media (min-width: 2000px){
    .RaDatagrid-table th, .RaDatagrid-table tbody tr td{
      padding: 15px 10px !important;
    }
}