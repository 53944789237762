.markercluster-map {
  height: 100vh;
}

.leaflet-marker-pane .leaflet-marker-icon {
  width: 40px !important;
}
.leaflet-container{
    height: 75vh !important;
    }

.leaflet-marker-pane img.leaflet-marker-icon{
  width: auto !important;
  height: auto !important;
}
.leaflet-control-container .leaflet-top.leaflet-left{
  right: 10px !important;
  bottom: 30px !important;
  left: unset !important;
  top: unset !important;
}

.leaflet-tooltip-left,.leaflet-tooltip{
  background: #7474F5;
  width: 200px;
  height: auto;
  border: 1px solid #7474F5 !important;
  border-radius: 6px !important;
  padding: 14px !important;
  color: #fff;
}
.leaflet-tooltip-left div, .leaflet-tooltip div{
  white-space: break-spaces;
}
.leaflet-tooltip-left b,.leaflet-tooltip b{
  margin-right: 4px;
  font-size: 12px;
  font-weight: bold;
}
.leaflet-tooltip-left:before{
  border-left-color: #7474F5;
}
.leaflet-tooltip-right:before{
  border-right-color: #7474F5;
}

.border-div{
  border-style: solid;
  border-color: #f3dd13;
}


.imgwrapper img {
  display: block;
}
.imgwrapper div {
  position: relative;
  display: inline-block;
  *display: inline;
  zoom: 1;
}


.leaflet-bottom.leaflet-right {
  display: none;
}
.leaflet-tooltip-right {
  margin-left: 30px;
}
.leaflet-tooltip-left {
  margin-left: 12px;
}
.leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  top: 56%;
}

