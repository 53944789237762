
@import "rsuite/dist/rsuite.css";


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* .MuiFormLabel-root,body{
  font-family: 'Open Sans', sans-serif !important;
} */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.login-sec{
  background: #A1A2F2 !important;
}
.login-sec .bg-image{
        background: url(./images/loginPage-bg.svg) !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        height: 80,
}
.login-sec .MuiPaper-root{
  padding: 42px;
  border-radius: 10px !important;
}
.login-sec img{
  height: 73px;
  /* margin-left: calc(100% - 56%); */
}
.login-sec .logo-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-sec h2{
  font-size: 18px;
  font-weight: 600;
  color: #252F40;
  margin-top: 8px;
}
.login-sec p{
  font-size: 15px;
  font-weight: normal;
  color: #67748E;
  margin-top: 8px;
}
.login-sec .MuiButton-root{
  background: #4646F2 !important;
  border: 1px solid #4646F2 !important;
  color: #fff !important;
  text-transform: capitalize !important;
}
.login-sec .MuiButton-root:hover{
  color: #fff !important;
}
.login-sec a.RaLink-link{
  text-decoration: underline !important;
  font-size: 12px !important;
  color: #67748E !important;
  position: relative;
  bottom: 12px;
}
.login-sec .MuiInputLabel-root span{
  color: #67748E !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}
.login-sec .dob-label .MuiInputLabel-root {
  margin-top: -20px !important;
}
.login-sec .dob-label .MuiInputLabel-root span{
  font-size: 16px !important;
}
.login-sec .MuiInputLabel-root{
  margin-top: -22px;
  margin-left: -10px;
  transform: translate(12px, 0px) !important;
}
.login-sec .MuiInputLabel-root[data-shrink="true"]{
  margin-top: -22px !important;
  
}
.login-sec .MuiBox-root .MuiFormControl-root .MuiFilledInput-input {
  background: #EDEDFF !important;
  font-size: 13px !important;
  border-radius: 5px;
  padding: 10px 28px 10px 14px;
}
.login-sec .Mui-focused .css-1zuho2-MuiInputBase-input-MuiFilledInput-input {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.login-sec .MuiBox-root .MuiFormControl-root .MuiFilledInput-root:before, 
.login-sec .MuiBox-root .MuiFormControl-root .MuiFilledInput-root:after {
  border-bottom: none !important;
}

.login-sec .MuiFormHelperText-root{
  font-size: 10px;
  margin: 0;
  margin-bottom: 1px;
}
.login-sec #username-helper-text, .login-sec #password-helper-text,
.login-sec #confirmPassword-helper-text,.login-sec #email-helper-text,
.login-sec #lastName-helper-text, .login-sec #firstName-helper-text,
.login-sec #phone-helper-text{
  display: none;
}
/* .login-sec .Mui-focused .MuiFilledInput-input{
  padding-top: 15px !important;
  padding-bottom: 8px !important;
} */
.login-sec label.Mui-focused{
  margin-top: -22px !important;
}

.forgotpwd button{
  color: #FFFFFF !important;
  text-align: center;
  font-size: 15px;
  font-weight: normal;
}

.forgotpwd .backtopage h4{
  color: #252F40 !important;
  font-size: 14px !important;
  font-weight: normal !important;
}
.forgotpwd .backtopage svg{
  fill:#252F40 !important;
}
.forgotpwd h4{
  color: #7474F5 !important;
  font-weight: 600;
  font-size: 16px !important;
  margin-top: 15px;
}
.forgotpwd .f-text{
  color: #252F40 !important;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 22px;
  text-align: center;
}
.login-sec.forgotpwd a.RaLink-link{
  bottom: 0 !important;
  color: #fff !important;
  text-decoration: none !important;
  font-size: 14px !important;
  font-weight: normal !important;
}
/* spinner css */
.spinnerDiv{
  background-color: white;
  height: 100vh;
  opacity: 0.6;
}

.MuiSnackbar-root{
  top: 100px !important;
}
.MuiSnackbar-root.MuiSnackbar-anchorOriginBottomCenter{
left: 45% !important;
bottom: auto !important;
}
.login-sec.forgotpwd .Sub-btn{
  background: #4646F2 !important;
  border: 1px solid #4646F2 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  padding: 12px;
  border-radius: 4px;
  width: 100%;
  margin-top: -25px;
  text-align: center;
}
.login-sec.forgotpwd .otp-field{
  padding: 0 1em 1em 1em;
  display:flex;
  justify-content: center;
}
.login-sec.forgotpwd .otp-field input{
    width: 30px;
    height: 30px;
    margin: 0px 10px;
    background: #EDEDFF;
    border-radius: 6px;
    border: none;
    padding:8px
}
.login-sec.forgotpwd .otp-field input:focus{
  outline: none;
}
.login-sec.forgotpwd .resend-p{
  color: #67748E;
  font-size: 14px;
  text-align: center;
}
.login-sec.forgotpwd .resend-p a{
  font-weight:600;
  color: #4646F2;
  font-size: 14px;
  text-decoration: none;
}
.login-sec .signup-p{
  color: #67748E;
  font-size: 14px;
  text-align: center;
}
.login-sec .signup-p a{
    font-weight: 600;
    color: #4646F2 !important;
    font-size: 14px !important;
    text-decoration: none !important;
    bottom: 0 !important;
}
/* .login-sec.newreg .MuiFormHelperText-root{
  display: none;
} */
.login-sec.newreg .Sub-btn{
  background: #4646F2 !important;
  border: 1px solid #4646F2 !important;
  color: #fff !important;
  text-transform: capitalize !important;
  padding: 12px;
  border-radius: 4px;
  width: 100%;
  margin-top: 10px;
  text-align: center;
  text-decoration: none !important;
}
.pt-0{
  padding-top: 0 !important;
}
.rc-anchor-alert {
  color: red;
  font-size: 12px;
  margin: 2px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}



@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #00000087;
  position: absolute;
  width: 100%;
  z-index: 999;
}
.spinner-container img{
  height: 150px !important;
  animation: zoom-in-zoom-out 1s ease infinite;
  margin-left: -48px !important;
}
.spinner-container span{
    position: absolute;
    left: calc(100% - 53%);
    top: calc(100% - 38%);
    color: #ececec;
    font-size: 12px;
}

.top-down .css-26l3qy-menu{
  position: absolute;
  inset: auto auto 0px 0px;
  bottom: 35px;
  /* transform: translate3d(0px, -34px, 0px); */
}
.top-down .react-datepicker-popper .react-datepicker__triangle::before,
.top-down .react-datepicker-popper .react-datepicker__triangle::after{
  display: none;
}
.top-down .react-datepicker-popper{
  inset: auto auto 0px 0px !important;
}
.basic-multi-select .select__indicators .select__indicator:nth-of-type(2){
  display: none;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list
{
  height: 155px !important;
}
@media only screen and (min-width: 768px) and (max-width:901px){
  .login-sec:not(.newreg ) .MuiSvgIcon-root {
    right: calc(100% - 86.6%);
  }
}
@media only screen and (min-width: 902px) and (max-width:1079px){
  .login-sec:not(.newreg ) .MuiSvgIcon-root {
    right: calc(100% - 84.6%);
  }
}
@media only screen and (min-width: 1080px) and (max-width:1200px){
  .login-sec:not(.newreg ) .MuiSvgIcon-root {
    right: calc(100% - 86.6%);
  }
}
@media (min-width: 2000px){
  .login-sec:not(.newreg ) .MuiSvgIcon-root {
    right: calc(100% - 88.6%);
  }
  .login-sec.newreg .conf-pwd .MuiSvgIcon-root {
    right: calc(100% - 89.5%);
  }
  .list-content{
    height: 75vh !important;
  }
}

@media only screen and (min-width:565px) and (max-width:991px){
  .login-sec .MuiPaper-root{
    min-width: 220px;
  }
}


.drop-notification{
  right: 44px !important;
}
.drop-notification .notifications {
  padding-left: 0 !important; 
  width: 300px;
 }
 .drop-notification .notifications-wrapper {
  overflow:auto;
  max-height:250px;
  }
   
.drop-notification .menu-title {
  color: #252F40;
  font-size: 16px;
  display: inline-block;
  font-weight: 600;
  margin: 0 !important;
  }
  .drop-notification .menu-title.pull-right
  {
    color: #67748E;
    font-weight: normal !important;
    font-size: 14px !important;
    cursor: pointer;
  }
.drop-notification .notification-heading, .drop-notification .notification-footer  {
  padding:2px 10px;
}        
.drop-notification .dropdown-menu.divider {
  margin:5px 0;          
 }
.drop-notification .item-title {
  font-size:14px;
  color: #252F40;
  font-weight: 600;
  margin: 0;
}
.drop-notification .item-detail{
  margin: 0 0px 0px 10px;
}
.notification-item .item-info{
  margin: 4px 0;
  font-size: 13px;
  font-weight: normal !important;
  color: #554F60 !important;
}
.drop-notification .pull-right {
  float: right!important;
}
.drop-notification .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
.drop-notification .notifications a.content {
  text-decoration:none;
  background:#ccc;
}  
.drop-notification .notification-item {
  padding:10px;
  background:#fff;
  border-radius:2px;
  display: flex;
  margin: 0px 5px;
}
.drop-notification .notification-item:hover{
  background:#E2E2FD !important;
}
.drop-notification .notification-item img{
  height: 30px;
}
.drop-notification .notification-item .item-date{
  color:#67748E;
  font-size:12px;
  font-weight:500;
  margin: 10px 0 0;
}
.drop-notification .notification-item .item-date.span{
  margin-left: 8px;
}

.drop-notification .divider:last-child{
  display: none;
}
.drop-notification .MuiPaper-root{
  top: 47px !important;
  right: 16px;
  left: calc(100% - 258px) !important;
  max-width: 350px !important;
  max-height: 400px !important;
  width: 300px !important;
}
.drop-notification *::-webkit-scrollbar {
  width: 7px;
}

.drop-notification *::-webkit-scrollbar-track {
  background: #EBEBFD;
}

.drop-notification *::-webkit-scrollbar-thumb {
  background-color: #4646F2;
  border-radius: 50px;
}

.drop-notification .notification-item .close-icon svg{
  fill: #67748E;
  width: 0.6em;
  height: 0.6em;
}
.drop-notification::before{
    position: absolute;
    z-index: 1;
    content: "";
    top: 38px;
    border-color: transparent transparent #fff;
    border-style: solid;
    border-width: 0 10px 10px;
    transition-duration: .3s;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform,-webkit-transform;
    left: calc(100% - -0.4%);
}
.load-more{
  display: flex;
  align-items: center;
  justify-content: center;
}
.load-more button{
  background: #fff;
  color: #4646F2;
  padding: 8px;
  border-radius: 2px;
  border: 1px solid #4646F2;
  width: 120px;
  margin-bottom: 4px;
  text-transform: inherit;
  box-shadow: none;
}
.load-more button:hover{
  background: #fff!important;
  color: #4646F2 !important;
  box-shadow: none !important;
}
.load-more button.Mui-disabled{
  border: none !important;
}
.RaNotification-warning{
  top: -55px !important;
  position: absolute;
}
.vts-form .search-btn {
  background: #4646F2;
  padding: 8px;
  border-radius: 6px;
  height: 35.25px;
  position: relative;
  top: 4px;
  margin-right: 23px;
  border: 1px solid #4646F2;
}
.vts-form .search-btn:hover {
  background: #fff !important;
  color: #4646F2 !important;
  border: 1px solid #4646F2;
}
.vts-form .search-btn svg {
  fill: #fff;
}
.vts-form .search-btn:hover svg {
  fill: #4646F2 !important;
}
.vts-form .filter-field[data-source="q"] .RaFilterFormInput-spacer {
  width: 5px !important;
}

.vts-form .filter-field[data-source="q"] .MuiFormControl-root.ra-input input{
  width: 200px;
}

.pop-over p{
  color: #252F40;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0;
  word-break: break-all;
}
.pop-over p span{
  color: #67748E;
  font-size: 12px;
  font-weight: 400 !important;
  margin-left: 5px;
}
.MuiAutocomplete-popper .MuiAutocomplete-listbox{
  font-size: 12px !important;
}
.name-field label {
  color: #67748E !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  margin-top: -18px !important;
}

@media only screen and (min-width: 1051px) and (max-width:1132px)
{
.g-recaptcha iframe[title="reCAPTCHA"]{
  right: 28px !important;
  position: relative;
}
}
@media only screen and (min-width: 993px) and (max-width:1050px)
{
.g-recaptcha iframe[title="reCAPTCHA"]{
  right: 43px !important;
  position: relative;
}
}
@media only screen and (min-width: 769px) and (max-width:992px)
{
.g-recaptcha iframe[title="reCAPTCHA"]{
  right: 52px !important;
  position: relative;
}
}