.cpanel-sec .show-btn.add{
    min-width: 1px;
    margin-left: -11px;
    padding: 5px !important;
    margin-top: 20px;
  }
  .cpanel-sec .show-btn.add span{
    margin-right: 0 !important;
  }
  .cpanel-sec .audit-left {
    /* padding: 25px 32px;
    width: 93.5%;
    height: auto; */
    padding: 25px 15px;
    height: 70vh;
  }
  .cpanel-sec .audit-left .MuiAutocomplete-endAdornment .MuiAutocomplete-popupIndicator svg path {
    d: path('M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z') !important;
  }
  .cpanel-sec .audit-left .MuiAutocomplete-endAdornment svg{
    fill:#67748E !important;
  }
  .cpanel-sec .audit-left .or-hr hr{
    width: 28px;
    height: 0px;
    background: #ababab;
}
.cpanel-sec .audit-left .MuiOutlinedInput-root{
    width: 100%;
}
.cpanel-sec .audit-left hr{
    margin: 26px 0 !important;
}
.cpanel-sec .audit-left .or-hr span{
    font-size: 12px;
    color: #ababab;
    margin: 0 5px;
}
.cpanel-sec .audit-left .MuiAutocomplete-root input{
    padding: 8.5px 14px !important;
}
/* .cpanel-sec .audit-left .Search-drop input{
  padding: 3.5px 14px !important;
} */
.cpanel-sec .audit-left .Search-drop .css-1insrsq-control{
  height: 40px;
  line-height: 6px;
}
.cpanel-sec .audit-left textarea{
    padding: 10px;
    border-radius: 4px;
    border: none;
}
.cpanel-sec .audit-left label{
    font-weight: bold;
    color: #252F40;
    font-size: 13px;
    top: 2px;
    left: 9px;
}
.cpanel-sec .audit-left label[data-shrink="true"]{
  top: 0 !important;
  left: 0 !important;
}
.cpanel-sec .audit-left label[data-shrink="true"]{
  display: none;
}
.css-26l31qy-menu{
  z-index: 9999999;
}
.cpanel-sec .audit-left label.Mui-focused{
  top: -1px !important;
}

.cpanel-sec .audit-left .MuiFilledInput-root::after{
  border-bottom: none !important;
}
.cpanel-sec .audit-left textarea:focus{
    outline: none;
}

.cpanel-sec .show-btn{
    padding: 10px 20px!important;
    float: right;
    margin-bottom: 20px;
    float: right !important;
    margin-right: 20px !important;
    bottom: 27px;
}
.cpanel-sec .audit-left .show-btn span,.cpanel-sec .audit-left .refreshbtn span{
    margin-right: -2px !important;
}
.cpanel-sec .user-mapModal .modal-footer .continue-btn{
    width: 60px !important;
    border-radius: 5px !important;
}
/* User mapping Modal */
.user-mapModal h2#modal-modal-title{
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
  }
  .user-mapModal h4{
    text-align: center;
    font-size: 16px;
    font-weight: 600;
  }
  .user-mapModal p{
    text-align: center;
    font-size: 12px;
    margin-top: 6px;
  }
 .user-mapModal .modal-box{
    text-align: center;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 8px !important;
  }
 
  .user-mapModal svg.verified-icon{
    border-radius: 18px;
    border: 2px solid green;
    fill: green;
    padding: 3px;
    margin-left: 18px;
    margin-bottom: 12px;
  }
  .user-mapModal .modal-footer .continue-btn span{
    margin-right: 0 !important;
  }
  .user-mapModal .modal-footer .continue-btn{
    background-color: #4646F2;
    color: #fff;
    box-shadow: none;
    padding: 5px 10px;
    border: 1px solid #4646F2;
    width: 120px;
    border-radius: 2px;
  }
  .user-mapModal .modal-footer button:hover{
    background-color: #fff;
    color: #4646F2;
    border: 1px solid #4646F2;
  }
  
  .Search-drop .e-multiselect{
    border: none !important;
    background: #f0f0ff;
    border-radius: 4px;
    height: 32px;
  }
  .Search-drop{
    display: flex;
    /* position: absolute; */
    width: 280px;
  }
  .Search-drop svg{
    position: relative;
    right: calc(100% - 92%);
    width: 1.7em;
    margin-top: 4px;
    fill: #a6b1ba;
    bottom: 4px;
  }
  .Search-drop .e-multiselect.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
  .Search-drop .e-multiselect.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after{
    background: transparent !important;
  }
  .cpanel-sec .MuiPaper-root {
    overflow-y: auto;
  }
  .cpanel-sec .audit-left .Search-drop .e-multiselect input {
    padding: 0px !important;
}

.cpanel-sec .map-content  *::-webkit-scrollbar {
  width: 7px;
  
}

.cpanel-sec .map-content  *::-webkit-scrollbar-track {
  background: #EBEBFD;
}

.cpanel-sec .map-content *::-webkit-scrollbar-thumb {
  background-color: #4646F2;
  border-radius: 50px;
}

.cpanel-sec .audit-left .MuiAutocomplete-endAdornment {
  /* right: 68px !important; */
  top: 12px !important;
}
.cpanel-sec .audit-left .MuiAutocomplete-root .MuiOutlinedInput-root,.cpanel-sec .audit-left .MuiAutocomplete-root .MuiFilledInput-root {
  padding-left: 0px;
  width: 280px !important;
  padding-right: 0 !important;
  background: #f0f0ff;
  border: none;
  padding-top: 6px;
}
.cpanel-sec .audit-left .css-tlfecz-indicatorContainer:first-child,
 .cpanel-sec .audit-left .css-1xc3v61-indicatorContainer:first-child, 
 .cpanel-sec .audit-left .css-15lsz6c-indicatorContainer:first-child{
  display: none;
}
.cpanel-sec .audit-left .css-1hb7zxy-IndicatorsContainer .css-1gtu0rj-indicatorContainer:nth-of-type(2),
.cpanel-sec .audit-left .css-1gtu0rj-indicatorContainer:nth-of-type(2){
  display: none;
}

.css-23lq6y-menu{
  overflow: visible;
  position: relative;
  z-index: 9999;
}
.cpanel-sec .audit-left .MuiAutocomplete-root .MuiInputLabel-root{
  font-weight: normal !important;
}
/* .cpanel-sec .audit-left .MuiAutocomplete-input {
  height: 32px !important;
} */

.Search-drop .e-multiselect .e-multi-select-wrapper{
    padding: 3px 8px;
}
/* Search dropdown */

.Search-drop .d-inline-block{
  width: 100%;
}
.Search-drop .d-inline-block .css-1s2u09g-control, .Search-drop .d-inline-block .css-13cymwt-control{
    background: #f0f0ff;
    border: none;
}
.Search-drop .d-inline-block .css-t3ipsp-control{
  background-color: #f0f0ff;
  border: none;
  box-shadow: none;
}
.Search-drop .d-inline-block .css-1jqq78o-placeholder{
  font-size: 12px;
  color: #2E2C33 !important;
  padding-left: 3px;
}
.Search-drop .d-inline-block .css-6j8wv5-Input, .Search-drop .d-inline-block .css-19bb58m{
  height: 24px;
}
/* .Search-drop .d-inline-block .css-14el2xx-placeholder{
  margin-bottom: 14px;
} */
.Search-drop .d-inline-block .css-1okebmr-indicatorSeparator, 
.Search-drop .d-inline-block  .css-1u9des2-indicatorSeparator{
  display: none;
}
/* .Search-drop .d-inline-block .css-1hb7zxy-IndicatorsContainer{
  width: 34px;
} */
.Search-drop .d-inline-block .css-tlfecz-indicatorContainer, .Search-drop .d-inline-block .css-1xc3v61-indicatorContainer{
  padding: 0;
  width: 20px;
}
.Search-drop .d-inline-block .css-1xc3v61-indicatorContainer svg{
  fill: rgba(0, 0, 0, 0.54);
  width: 1.2rem!important;
  height: 1.2rem !important;
  font-size: 1.5rem;
}
.Search-drop .d-inline-block .css-1xc3v61-indicatorContainer svg path {
  d: path('M7 10l5 5 5-5z') !important;
}
.Search-drop .d-inline-block .css-15lsz6c-indicatorContainer svg path{
  d: path('m7 14 5-5 5 5H7z') !important;
}
.Search-drop .d-inline-block .css-15lsz6c-indicatorContainer svg{
    fill: rgba(0,0,0,.54);
    font-size: 1.5rem;
    height: 1.2rem!important;
    width: 1.2rem!important;
}
.Search-drop .d-inline-block .css-1gtu0rj-indicatorContainer{
  padding: 0 !important;
}
.Search-drop .d-inline-block .css-g1d714-ValueContainer, .Search-drop .d-inline-block .css-1dyz3mf{
  padding: 4px;
}
.Search-drop .d-inline-block  .css-1rhbuit-multiValue, .Search-drop .d-inline-block .css-1p3m7a8-multiValue {
  background-color: #dbdbff;
}
.Search-drop .d-inline-block  .css-1rhbuit-multiValue .css-12jo7m5, .Search-drop .d-inline-block .css-9jq23d{
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
}
.Search-drop .d-inline-block  .css-1rhbuit-multiValue svg, .Search-drop .d-inline-block .css-1p3m7a8-multiValue svg{
  width: 0.7em !important;
}

@media only screen and (min-width: 0px) and (max-width:1114px) {
  .Search-drop{
    width: 145px;
  }
}
@media only screen and (min-width: 1200px) and (max-width:1368px) {
  .cpanel-sec .cpanel-card.MuiPaper-root .audit-left{
    height: 90vh !important;
  }
  .cpanel-sec .map-content {
    height: calc(100vh - 116px);
  }
  .cpanel-sec .cpanel-card-2.MuiPaper-root .audit-left, .cpanel-sec .cpanel-card-3.MuiPaper-root .audit-left{
    height: 67vh !important;
  }
}
@media only screen and (min-width: 1369px) and (max-width:1600px) {
  .cpanel-sec .cpanel-card.MuiPaper-root,.cpanel-sec .cpanel-card-2.MuiPaper-root,.cpanel-sec .cpanel-card-3.MuiPaper-root{
    height: 65vh !important;
  }
  .cpanel-sec .audit-left{
    height: 60vh !important;
  }
}