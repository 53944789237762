.Button-toolbar{
    width: 76% !important;
    margin-right: 4px;
}
.Button-toolbar .uploadbtn{
    position: absolute !important;
    right: 160px!important;
    border: 1px solid #4646F2 !important;
    color: #4646F2 !important;
    background: #fff !important;
    text-transform: capitalize !important;
    border-radius: 6px !important;
    font-size: 13px;
    height: 38px;
    bottom: 12px;
}
.Button-toolbar .uploadbtn .MuiSvgIcon-root{
    fill: #4646F2 !important;
    width: 1rem !important;
    height: 1rem !important;
}
.Button-toolbar .uploadbtn span.MuiButton-startIcon{
  margin-right: 4px !important;
}
.Button-toolbar .Addentity,.Button-toolbar .Addentity:hover{
  width: 147px !important;
}
.Button-toolbar button.Addentity{
  border-radius: 6px !important;
  color: #FFFFFF;
  font-size: 13px;
  height: 38px;
  right: 0;
  bottom: 12px;
}
.Button-toolbar button.Addentity svg{
  width: 1rem !important;
  height: 1rem !important;
}
.Button-toolbar button.Addentity span.MuiButton-startIcon{
  margin-right: 4px !important;
}

.Button-toolbar  .Switchbtn{
    position: absolute !important;
    right: 262px !important;
    bottom: 12px;
}
.Button-toolbar .list{
    position: absolute;
    right: 367px;
    bottom: 22px;
    color: #000;
}
.Button-toolbar .top-tlcount{
    position: absolute;
    left: -8px;
    bottom: 10px;
}
.Button-toolbar input[type="checkbox"]{
    width: 155px;
    height: 36px;
    appearance: none;
}
.geofence-left .MuiInputBase-input{
    /* height: 0px !important; */
    padding: 10px 10px;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    background: #f0f0ff!important;
    border-radius: 5px!important;
    font-size: 14px!important;
}
.AddgeofModal .geofence-left .Mui-focused .MuiInputBase-input{
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.AddgeofModal .geofence-left .range-slider .MuiFormLabel-root, .AddgeofModal .geofence-left .range-slider .field span {
  font-size: 12px;
  color: #330202;
  font-weight: 600;
}
.AddgeofModal .geofence-left .range-slider .field{
  display: flex;
}
.AddgeofModal .geofence-left .range-slider .field input{
  width: 50px;
  margin-left: 75px;
  margin-right: 5px;
  border: 1px solid #bbbbbb;
  border-radius: 4px;
  padding: 4px;
}
.AddgeofModal .geofence-left .range-slider .field input:focus{
  outline: none;
  box-shadow: none;
}
.AddgeofModal .geofence-left .range-slider .field span{
  margin-top: 6px;
}
.AddgeofModal .geofence-left .range-slider .range-field{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.AddgeofModal .geofence-left .range-slider .range-field span{
  font-size: 14px;
  color: #330202;
  font-weight: 500;
}
.AddgeofModal .geofence-left .range-slider .slider {
 color: #858585;
}
.AddgeofModal .geofence-left .range-slider .slider .MuiSlider-track{
  background: #000;
}
.AddgeofModal .geofence-left .range-slider .slider .MuiSlider-mark{
  background: transparent !important;
}
.AddgeofModal .geofence-left .range-slider .slider .MuiSlider-thumb{
  color: #000;
  border: 5px solid #000;
}

.geofence-left .MuiSelect-select {
    padding: 4px;
    padding-top: 5px !important;
    height: unset!important;
    min-height: unset!important;
}
.geofence-left .MuiFormControl-root{
    width: 100% !important;
}
.geofence-left .left-checkbox .MuiFormLabel-root{
    color: #000 !important;
    margin-left: 5px;
    bottom: 1px;
}
.geofence-left .area-button.active{
    border: 1.5px solid #4646F2 !important;
    text-transform: capitalize;
    color: #4646F2 !important;
}
.geofence-left .area-button{
    border: 1px solid #000;
    text-transform: capitalize;
    color: #000;
}
.geofence-left .area-button .css-1d6wzja-MuiButton-startIcon{
    margin-right: 1px !important;
}
.geofence-left .area-button .MuiButton-startIcon svg{
    width: 0.8rem !important;
    height: 0.8rem !important;
}
.geofence-left .area-button.active svg{
    fill: #4646F2 !important;
}


.AddgeofModal .button {
    float: left;
    margin: 0 5px 0 0;
    height: 40px;
    position: relative;
    
  }
  
  .AddgeofModal .button label,
  .AddgeofModal .button input {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .AddgeofModal .button input[type="radio"] {
    opacity: 0.011;
    z-index: 100;
    cursor: pointer;
  }
  .AddgeofModal textarea{
    height: 150px !important;
    background: rgb(240, 240, 255);
    overflow: hidden;
    border-radius: 4px;
    border: none;
    padding: 10px;
  }
  .AddgeofModal textarea:focus{
    outline: none !important;
  }
  
  .AddgeofModal .button input[type="radio"]:checked + label {
    border-radius: 4px;
    border: 1.5px solid #4646F2 !important;
    text-transform: capitalize;
    color: #4646F2 !important;
  }
  
  .AddgeofModal .button label {
    z-index: 90;
    line-height: 2.5em;
    font-size: 14px;
    font-weight: 600;
    border: 1px solid #000;
    text-transform: capitalize;
    color: #000;
    border-radius: 4px;
    display: flex;
  }
  .AddgeofModal .button label span{
    margin-left: 4px;
  }
  .AddgeofModal .button svg{
    width: 0.8rem !important;
    height: 0.8rem !important;
    margin-top: 12px;
    margin-left: 4px;
}
.AddgeofModal .button input[type="radio"]:checked + svg{
    fill: #4646F2 !important;
}
.AddgeofModal .show-btn.add{
    min-width: 1px;
    margin-left: -11px;
    padding: 9px 14px !important;
    margin-top: 28px;
  }
.AddgeofModal .show-btn.add span{
    margin-right: 0 !important;
  }
  .AddgeofModal .geofence-left .MuiFilledInput-root::after,
  .AddgeofModal .geofence-left .MuiFilledInput-root::before,
  .AddgeofModal .geofence-left .MuiFilledInput-root:hover::before{
    border-bottom: none !important;
  }
.show-btn{
    background-color: #4646F2 !important;
    color: #fff !important;
    padding: 10px !important;
    border-radius: 6px !important;
    text-transform: capitalize !important;
    border: 1px solid #4646F2 !important;
    height: 38px;
    display: flex;
    align-items: center;
}
.show-btn:hover{
    background-color: #fff !important;
    color: #4646F2 !important;
    border: 1px solid #4646F2 !important;
}
.show-btn span{
  margin-right: 0px !important;
}
.AddgeofModal .geof-box{
    width: 90% !important;
    height: 580px !important;
    border: none !important;
    border-radius: 2px !important;
    padding: 18px !important;
} 
.AddgeofModal h2#modal-modal-title {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 12px;
}
.AddgeofModal .geofence-left{
    padding: 15px;
    width: 90%;
}

.AddgeofModal .geof-box .leaflet-container {
  height: 500px !important;
}

/* Scroll bar works on chrome, Edge and Safari */

.AddgeofModal *::-webkit-scrollbar {
    width: 7px;
  }
  
  .AddgeofModal *::-webkit-scrollbar-track {
    background: #EBEBFD;
  }
  
  .AddgeofModal *::-webkit-scrollbar-thumb {
    background-color: #4646F2;
    border-radius: 50px;
  }

  /* Bulk upload modal styles */
  .bulkup-modal  h2{
    color: #363B45;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 15px;
  }
  .bulkup-modal .uploader {
    display: block;
    clear: both;
    margin: 0 auto;
    width: 100%;
  }
  .bulkup-modal .uploader label {
    float: left;
    clear: both;
    width: 100%;
    height: 150px;
    text-align: center;
    transition: all 0.2s ease;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    background: #EDEDFF;
    border: 1px dashed #D0D1F8;
    border-radius: 6px;
  }
  
  .bulkup-modal .uploader label .imagePreview p {
    color: #67748E;
    font-size: 11px;
    font-weight: 400;
    line-height: 18px;
    margin-top: -5px;
    margin-bottom: 0px;
    padding: 2px 6px;
  }
  .bulkup-modal .normal-a{
    color: #67748E;
    font-size: 11px;
    font-weight: 500;
    text-align: center;
    margin: 15px 0;
    display: inline-block;
    text-decoration: none;
  }
  .bulkup-modal .normal-a:hover{
    color: #45C370;
  }
  .bulkup-modal .cancel-btn{
    border: 1px solid #4646F2;
    border-radius: 6px;
    background-color: #fff;
    padding: 8px;
    color: #4646F2;
    text-align: center;
    text-transform: capitalize;
    box-shadow: none;
    width: 100%;
    margin-top: 10px;
    height: 46px;
  }
  .bulkup-modal .cancel-btn:hover{
    color: #Fff !important;
    box-shadow: none;
    background: #4646F2;
    border: 1px solid #4646F2;
  }
  .bulkup-modal  .upload-btn{
    background: #4646F2;
    border: 1px solid #4646F2;
    border-radius: 6px;
    padding: 10px;
    color: #Fff;
    text-align: center;
    text-transform: capitalize;
    box-shadow: none;
    width: 100%;
    margin-top: 10px;
    height: 46px;
  }
  .bulkup-modal  .upload-btn:hover{
    border: 1px solid #4646F2;
    background-color: #fff;
    color: #4646F2;
    box-shadow: none;
  }
  .bulkup-modal .uploader label .imagePreview p i {
    color: #878080;
    font-size: 14px;
    margin-left: 5px;
  }
  .bulkup-modal .uploader label .imagePreview span{
    font-size: 12px;
    color: #45C370;
    word-break: break-all;
  }
  .bulkup-modal .uploader label .imagePreview span svg{
    font-size: 12px;
    position: relative;
    top: 3px;
    margin-right: 2px;
  }
  .bulkup-modal .uploader #start {
    height: 80px;
    color: #7474F5;
    margin: 8px 30px 0;
  }
  .bulkup-modal .uploader #start p{
    color: #7474F5 !important; 
  }
  .bulkup-modal .uploader #start svg{
    font-size: 30px;
  }
  .bulkup-modal .uploader #start.hidden {
    display: none;
  }
  .bulkup-modal .uploader #start i {
    font-size: 18px;
    transition: all 0.2s ease-in-out;
    margin-top: 12px;
    color: #878080;
  }
  .bulkup-modal .uploader #file-image {
    display: inline;
    margin-top: -67px;
    width: auto;
    height: 146px;
    max-width: 180px;
  }
  .bulkup-modal .uploader #file-image.hidden {
    display: none;
  }
  .bulkup-modal .uploader input[type=file] {
    display: none;
  }

  .sucess-file-alert{
    left: 42% !important;
    right: auto !important;
    top: 110px !important;
    bottom: auto !important;
  }
  .sucess-file-alert .MuiPaper-root{
    background-color: #FFFFFF;
    box-shadow: 0px 20px 27px #00000029;
    border-radius: 12px;
    width: 320px;
    padding: 0px 0px !important;
  }
  .sucess-file-alert .MuiPaper-root h4{
    color: #252F40;
    font-weight: 600;
    text-align: left;
    font-size: 13px;
    margin: 0;
  }
  .sucess-file-alert .MuiPaper-root p{
    color: #67748E;
    font-size: 12px;
    margin: 0;
    word-break: break-all;
  }
  .sucess-file-alert .MuiAlert-icon {
    background: #45C370;
    box-shadow: 0px 20px 27px #00000029;
    border-radius: 12px;
    padding: 16px;
    align-items: center;
  }
  .sucess-file-alert .MuiAlert-filledError .MuiAlert-icon {
    background: red;
    box-shadow: 0px 20px 27px #00000029;
    border-radius: 12px;
    padding: 16px;
  }
  /* .sucess-file-alert .MuiAlert-message {
    overflow: auto;
    height: 40px;
    width: 100%;
  } */

  .sucess-file-alert *::-webkit-scrollbar {
    width: 5px;
  }
  
  .sucess-file-alert *::-webkit-scrollbar-track {
    background: #EBEBFD;
  }
  
  .sucess-file-alert *::-webkit-scrollbar-thumb {
    background-color: #878080;
    border-radius: 50px;
  }

  @media (min-width: 2000px){
    .AddgeofModal .geof-box, .AddgeofModal .geof-box .MuiPaper-root{
      height:auto !important;
    }
    .AddgeofModal .geof-box .leaflet-container {
      height: 752px!important;
    }
  }