.address_styling{
    text-decoration: underline;
    cursor:pointer;
    color: blue;
}
.load_more{
    text-decoration: underline;
    cursor:pointer;
    color: blue;
    margin-left: 110px;
}
.sel-group .css-1s2u09g-control,.sel-group .css-1pahdxg-control,.sel-group .css-13cymwt-control,.sel-group .css-t3ipsp-control{
    background: #f0f0ff;
    border: none;
    box-shadow: none;
}
.sel-group .css-tlfecz-indicatorContainer svg path, .sel-group .css-1xc3v61-indicatorContainer svg path{
    d: path('M7 10l5 5 5-5z') !important;
}
.sel-group .css-1gtu0rj-indicatorContainer svg path, .sel-group .css-15lsz6c-indicatorContainer svg path {
    d: path('m7 14 5-5 5 5H7z') !important;
}
.sel-group .css-14el2xx-placeholder, .sel-group  .css-1jqq78o-placeholder{
    color: rgba(0, 0, 0, 0.6);
}
.sel-group .css-tlfecz-indicatorContainer svg, .sel-group .css-1gtu0rj-indicatorContainer svg,
.sel-group .css-1xc3v61-indicatorContainer svg, .sel-group .css-15lsz6c-indicatorContainer svg
{
    fill: #000;
    width: 1.1rem!important;
    height: 1.1rem !important;
}
.sel-group .css-26lqy-menu,.sel-group .listbox{
    font-size: 12px !important;
}
.sel-group .css-b62m3t-container{
    z-index: 99;
}
.sel-group .css-1okebmr-indicatorSeparator, .sel-group .css-1u9des2-indicatorSeparator{
    display: none;
}
.sel-group:focus{
    outline: none;
}
.sel-group .css-14el2xx-placeholder, .sel-group .css-1jqq78o-placeholder{
    font-size: 14px;
}
.sel-group .css-qc6sy-singleValue{
    font-size: 14px;
    color: #2E2C33 !important;
}
.vehicle-sec .vts-form .filter-field[data-source="q"] .MuiFormControl-root.ra-input {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    height: 38px;
}
.vehicle-sec .vts-form .ra-input .MuiFilledInput-input {
    padding-top: 12.75px !important;
    padding-bottom: 8px !important;
}
.vehicle-sec .refreshbtn{
    position: absolute;
    right: 100px;
}
.vehicle-sec  .vts-form .search-btn{
    position: absolute;
    right: 190px;
    top: 14px;
}
.vehicle-sec  .vts-form .MuiBox-root span form{
    position: absolute;
    right: 242px;
    bottom: 8px;
}
.vehicle-sec .Map-list .vts-form .MuiBox-root span form{
    position: unset !important;
}
.vehicle-sec .Map-list .vts-form .search-btn{
    position: relative !important;
    right: 0;
    top: 6px;
    left: -4px;
}
.vehicle-sec .Map-list .refreshbtn{
    position: relative !important;
    right: 0;
    bottom: 12px;
}
.vehicle-sec .vts-form .sel-group{
    width: 200px;
}
.vehicle-sec .MuiToolbar-root .vts-form .MuiFilledInput-root{
    padding-right: 0 !important;
    padding-top: 0;
    padding-left: 0 !important;
}
.vehicle-sec .MuiToolbar-root .vts-form .filter-field .MuiAutocomplete-root .MuiInputLabel-root[data-shrink="true"],.vehicle-sec .MuiToolbar-root .vts-form .filter-field .MuiFilledInput-root::after{
    display: none !important;
}
.vehicle-sec .MuiToolbar-root .vts-form .filter-field .MuiAutocomplete-root .MuiFilledInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
    padding-bottom: 10px !important;
    padding-top: 8px !important;
    padding-left: 8px;
}
.vehicle-sec .MuiToolbar-root .vts-form .filter-field .MuiAutocomplete-root .MuiInputLabel-root{
    padding-left: 5px !important;
}
.vts-form button.MuiButton-root[type="submit"] {
    top: 6px;
}
.vehicle-toolbar .top-tlcount button span{
    margin-right: 0 !important;
}
.action-field{
    cursor: pointer;
    color: aqua;
}
.action-field svg{
    fill: #67748E;
}
.action-field:hover svg{
    fill: orange;
}
.action-field[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
}
.action-field[data-title]:after {
    content: attr(data-title);
    color: orange;
    font-size: 8px;
    position: absolute;
    bottom: -1.6em;
    left: -10px;
    white-space: nowrap;
    visibility: hidden;
}
.action-field[data-title] {
    position: relative;
    bottom: 5px;
}
.command-modal .modal-box{
    box-shadow: 0px 3px 6px #00000029;
    border: 0.20000000298023224px solid #70707038;
    border-radius: 12px;
    outline: none;
}
/* .command-modal .MuiBackdrop-root{
    background-color: transparent !important;
} */
.command-modal .modal-box h2{
    color: #363B45;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 600;
}
.command-modal .modal-box h4{
    color: #2E2C33;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
}
.command-modal .modal-box p{
    color: #2E2C33;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
}

.command-modal .modal-box .MuiFormControl-root label{
    color: #67748E;
    text-transform: capitalize;
    font-size: 14px;
}
.command-modal .modal-box textarea{
    background: #EDEDFF;
    border-radius: 6px;
    height: 120px !important;
    border: none;
    padding: 8px;
}
.command-modal .modal-box textarea:focus{
    box-shadow: none;
    outline: none;
}
.command-modal .modal-box .MuiFormControl-root {
    width: 100%;
}
.command-modal .modal-box .cancel-btn{
    border: 1px solid #4646F2;
    border-radius: 6px;
    background-color: #fff;
    font-size: 14px;
    color: #4646F2;
    font-weight: 500;
    box-shadow: none;
    padding: 8px 18px;
    text-transform: capitalize;
    margin-right: 0 !important;
}
.command-modal .modal-box .send-btn{
    background: #4646F2;
    border: 1px solid #4646F2;
    border-radius: 6px;
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    box-shadow: none;
    padding: 8px 18px;
    text-transform: capitalize;
}
.command-modal .modal-box .send-btn:hover{
    background: #fff;
    border: 1px solid #4646F2;
    color: #fff;
}
.command-modal .modal-box button{
    width: 80px;
    float: right;
}
.command-modal .modal-box button span{
    margin-right: 0 !important;
}
.command-modal .modal-box .succ-comment{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: -15px;
    position: relative
}
.command-modal .comment-modal-box {
    width: 300px;
    padding: 16px;
}
.command-modal .comment-modal-box .close-btn{
    top: -12px;
    right: -12px;
}
.command-modal .modal-box .successIcon{
    align-items: center;
    background: #45c370;
    border-radius: 12px;
    padding: 16px;
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}
.command-modal .modal-box .successIcon svg{
    width: 1em;
    height: 1em;
    fill: #fff;
}
.command-modal .comment-modal-box h2{
    text-align: center;
    font-size: 17px !important;
    text-transform: lowercase;
}
.command-modal .comment-modal-box h2::first-letter{
    text-transform: uppercase;
}
.command-modal .modal-box .comment-p{
    font-size: 14px;
    color: #67748E;
    font-weight: normal;
    text-align: center;
    margin-bottom: 15px;
    text-transform: lowercase;
}
.command-modal .modal-box .comment-p::first-letter{
    text-transform: uppercase;
}
.command-modal .modal-box .waiting-p{
    font-size: 14px;
    font-weight: normal;
    color: #4646F2;
    /* text-transform: lowercase; */
    text-align: center;
}
.command-modal .modal-box .waiting-p::first-letter{
    text-transform: uppercase;
}
.command-modal .comment-modal-box .comment-response{
    color: #252F40;
    font-size: 14px;
    font-weight: 600;
}
.command-modal .modal-box .waiting-p svg{
    fill: #4646F2;
    font-size: 14px;
    margin-bottom: -2px;
}
.sucess-file-alert .MuiPaper-root p{
    color: #67748E !important;
    font-size: 13px;
    font-weight: 500;
}

.driver-modal .MuiBox-root .MuiFormControl-root .MuiFilledInput-root {
    background-color: #f0f0ff!important;
    border: none!important;
    border-radius: 5px!important;
    color: #000;
    font-size: 12px;
    outline: none!important;
    
}
.driver-modal .MuiBox-root .MuiFormControl-root .MuiFilledInput-root:hover:not(.Mui-disabled):before {
    border-bottom:none !important
}
.driver-modal input {
    background: #f0f0ff!important;
    border: none!important;
    border-radius: 5px!important;
    color: #000;
    font-size: 12px;
    outline: none!important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.driver-modal .Mui-focused .css-1zuho2-MuiInputBase-input-MuiFilledInput-input{
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
.driver-modal .MuiInputBase-root:before, .driver-modal .MuiInputBase-root:after{
    border-bottom: none !important;
}
.driver-modal h2{
    text-align: center !important;
}
.driver-modal .modal-box{
    width: 480px !important;
    padding: 20px 30px;
}
.driver-modal .modal-box h4{
    margin-top: 18px;
}
.vehicle-sec .mapboxgl-ctrl-top-right .mapboxgl-ctrl{
    margin: 10px 4px 0 0 !important;
}

.vibrateicon{
    height: 15px;
    margin-left: 10px;
    margin-bottom: 4px
}
.moving-icon{
    border: 3px solid green;
    border-radius: 50%;
    padding: 5px;
}