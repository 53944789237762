.sos-toolbar{
    justify-content: start !important;
}
.sos-toolbar .list-search input{
    padding: 10px 14px !important;
    margin-top: 0px !important;
    margin-left: 0px !important;
    position: relative;
    top: 21px;
}
.panic-left .vts-form .status-b .MuiOutlinedInput-root .MuiSelect-select{
    padding: 7.5px 14px !important;
    width: 100px !important;
    margin-left: 8px;
}
.panic-left .vts-form .filter-field[data-source="q"] .MuiFormControl-root.ra-input {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    height: 38px;
}
.panic-left .sos-toolbar .date-range{
    padding-left: 0 !important;
}
.sos-toolbar .list-search .search-btn{
    top: 21px !important;
    margin-left: 5px;
}
.sos-toolbar .sel-group .date-range .MuiFormControl-root{
    margin-left: 0 !important;
}
.sos-toolbar label{
    color: #000 !important;
    font-size: 13px !important;
    margin-right: 8px;
}
.sos-toolbar .css-14vk25l{
    position: relative;
    top: 4px;
}
.sos-toolbar .MuiSelect-select.MuiInputBase-input{
    padding-right: 28px !important;
}
.sos-toolbar .date-range .MuiFormControl-root{
    margin-left: 0px !important;
}
.Panic-modal .css-1wnsr1i{
    width: 821px !important;
    height: 510px;
}
.Panic-modal .css-isbt42 > .MuiGrid-item{
    padding-top: 0 !important;
}
.Panic-modal .MuiSelect-select{
    padding: 8.5px 14px;
}

.Panic-modal table{
    padding: 30px!important;
}
.Panic-modal table tr td{
    padding-bottom: 15px !important;
    font-size: 14px !important;
}
.Panic-modal table tr td:first-child{
    font-size: 14px !important;
    font-weight: bold;
    color: #2E2C33;
}
.Panic-modal .css-1hllywr-MuiButtonBase-root-MuiButton-root{
    margin-right: 0 !important;
}
.Panic-modal .MuiButton-root{
    text-transform: capitalize;
}
.Panic-modal textarea{
    border-radius: 5px;
    border: none;
    padding: 10px;
}
.Panic-modal textarea:focus{
    box-shadow: none !important;
    outline: none !important;
}
.Panic-modal .MuiOutlinedInput-root{
    background: #f0f0ff;
   
}
.Panic-modal .MuiOutlinedInput-notchedOutline{
    border: none !important;
}
.panic-left .css-4dretl.Target-root{
    height: 250px !important;
    width: 100% !important;
}
.panic-left h4{
    font-size: 14px !important;
    color: #000 !important;
}
.panic-left .vts-form .date-range{
    height: 38px;
  }

.panic-left .MuiInputBase-input{
    padding:8px 14px !important;
}
.panic-left .filter-field .css-11qvwfa-MuiFormLabel-root-MuiInputLabel-root{
    margin-top: -6px !important;
}
.panic-left .MuiOutlinedInput-notchedOutline{
    border: none;
}
.panic-left .MuiPaper-root{
padding: 15px !important;
}
.panic-left .sos-toolbar{
    margin-bottom: 15px !important;
}
.panic-left .chart-right {
    padding: 8px !important;
    width: 380px !important;
    padding-top: 15px !important;
    margin-right: 50px !important;
}
.panic-left .chart-right h2{
    font-weight: bold !important;
    font-size: 22px !important;
    color: #252F40 !important;
    text-align: right;
    word-break: break-all;
    /* margin-top: 15px !important; */
}
.panic-left .chart-right p{
    color:#4646F2;
    text-align: right;
    font-size: 14px;
}
.panic-left .chart-right .right-move{
    justify-content: space-between;
    position: relative;
    left: 42px;
}
.panic-left .recharts-layer{
    fill: #4646F2 !important;
}
.panic-left .recharts-layer .recharts-text tspan{
    font-size: 13px;
    color: #67748E;
    text-align: center;
}
.panic-left .recharts-wrapper{
    margin-top: 30px;
}
.panic-left .vts-form .date-range .react-datepicker__navigation-icon{
    top: 6px !important;
}
.panic-left .vts-form .date-range .react-datepicker__navigation--previous {
    left: 12px !important;
}
.panic-left .vts-form .date-range .react-datepicker__navigation--next{
    right: 12px !important;
}
.panic-left .vts-form .date-range .MuiFormControl-root{
    margin-left: 0 !important;
    padding: 5px;
    height: 38px;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.panic-left .vts-form .date-range .cal-icon{
    right: 2px !important;
    bottom: 4px;
}
.chart-right .MuiTypography-root .MuiSvgIcon-root{
    position: relative;
    top: 8px;
    left: 20px;
}
/* .chart-right .arrow-right{
    position: relative;
    width: 100px;
} */
.recharts-cartesian-grid-vertical{
    visibility: hidden !important;
}
.panic-left .recharts-cartesian-axis-tick-line,.panic-left .recharts-yAxis .recharts-cartesian-axis-line{
    display: none;
  }
  .panic-left .vts-form .filter-field .MuiFormControl-root.ra-input {
    top: 0px !important;
    margin-left: 0px;
  }
  .panic-left .sel-group .date-range .MuiFormControl-root{
    top: 5px !important;
  }
  .address_styling{
    text-decoration: underline;
    cursor:pointer;
    color: blue;
  }

  @media (min-width: 1360px){
    .panic-left .chart-right .total-text{
        margin-left: 6.5rem;
    }
  }
@media (min-width: 2000px){
    .panic-left .recharts-wrapper ,.panic-left .recharts-wrapper .recharts-surface{
        height: 500px !important;
  }
  .panic-left .chart-right{
    margin-right: 150px !important;
    margin-top: 75px;
  }
}
@media only screen and (min-width: 992px) and (max-width:1046px) {
    .panic-left .recharts-wrapper .recharts-surface{
        width: 450px;
    }
}
@media only screen and (min-width: 1047px) and (max-width:1265px) {
    .panic-left .recharts-wrapper .recharts-surface{
        width: 500px;
    }
}
@media only screen and (min-width: 1171px) and (max-width:1265px) {
    .p-h2{
        width: 134px !important;
    }
}
@media (min-width: 1365px){
    .p-h2{
        width: 180px !important;
    }
}