.Addentity:hover{
    background: none !important;
    border:1px solid #4646F2;
    color: #000 !important;
  }
  .Addentity .MuiSvgIcon-root{
  fill: #fff !important;
  }
  .Addentity:hover .MuiSvgIcon-root{
  fill: #000 !important;
  }
  .Addentity:hover{
    width: 147px;
  }
  
  .Entitty-toolbar button.Addentity{
    border-radius: 6px !important;
    color: #FFFFFF;
    font-size: 13px;
    height: 38px;
    right: 0;
    bottom: 12px;
  }
  .Entitty-toolbar button.Addentity svg{
    width: 1rem !important;
    height: 1rem !important;
  }
  .Entitty-toolbar button.Addentity span.MuiButton-startIcon{
    margin-right: 4px !important;
 }

 .group-toolbar  button.Addgroup {
  border-radius: 6px !important;
  color: #FFFFFF;
  font-size: 13px;
  height: 38px;
  bottom: 12px;
}
.group-toolbar button.Addgroup svg{
  width: 1rem !important;
  height: 1rem !important;
}
.group-toolbar button.Addgroup span.MuiButton-startIcon{
  margin-right: 4px !important;
}

  .new-entitity .right-side form{
    height: 330px;
    overflow: auto;
  }
  .new-entitity .right-side .div-box{
   
    margin-top: 0 !important;
    padding-top: 5px;
}
  .new-entitity .right-side .MuiFormControl-root{
    width: 100%;
  }
  .new-entitity .right-side  .fetch-btn.MuiButton-root:hover {
    background: none !important;
    border: 1px solid #4646F2;
    color: #4646F2 !important;
}
  .new-entitity {
    margin: 30px 50px;
  }
  .new-entitity button:first-child{
    box-shadow: none !important;
    margin-left: 15px !important;
  }
  .new-entitity button{
    width: 47%;
    margin-right: 0 !important;
  }
  .new-entitity button.MuiAutocomplete-popupIndicator {
    width: 0 !important;
  }
  .new-entitity .MuiAutocomplete-popupIndicator:hover {
    background-color: transparent;
  }
  .new-entitity .div-box .MuiFormControl-root .MuiInputBase-root.field-select{
    margin-top: 8px;
    margin-bottom: 4px;
  }
  .new-entitity .modal-box{
    outline: none !important;
    width: 100% !important;
    border-radius: 2px !important;
    height: 500px;
    overflow: auto;
    padding-right: 15px !important;
  }
  .new-entitity .modal-box .MuiTabs-flexContainer {
    position: absolute;
    left: 9%;
  }
  .new-entitity .MuiTabs-root .MuiButtonBase-root{
    margin-right: 0 !important;
  }
  .new-entitity input,.new-entitity .Mui-focused input,
  .new-entitity .MuiFilledInput-input{
    padding: 8.5px 4px !important;
  }
  .new-entitity .MuiInputBase-root{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .new-entitity input:focus{
    outline: none !important;
  }
  .new-entitity .MuiOutlinedInput-notchedOutline{
    border: none !important;
  }
  .new-entitity .sub-title{
    font-size: 17px;
    font-weight: 600;
    text-align: left;
    color: #67748e;
  }
  .new-entitity textarea{
    background: #f0f0ff;
    border: none;
    width:187px !important;
    border-radius: 5px !important;
    padding: 10px;
  }
  .new-entitity textarea:focus{
    box-shadow: none !important;
    outline: none!important;
  }
  .new-entitity label.MuiFormLabel-root.MuiFormLabel-colorPrimary {
    padding-top: 0;
    color: #67748E;
  }
  .new-entitity .MuiAutocomplete-popper .MuiAutocomplete-listbox{
    height: 150px;
    z-index: 9999;
    
  }
  .new-entitity label.MuiFormLabel-root.MuiFormLabel-colorPrimary::first-letter{
    text-transform: uppercase !important;
  }
  .new-entitity .name-permit  .css-16f1609 .MuiFormLabel-root{
    line-height: 0 !important;
  }
  .new-entitity .right-side .MuiGrid-item{
    padding-top: 0px !important;
    padding-right: 15px !important;
    padding-left: 25px;
  }
  .new-entitity .select-auto .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0px 4px 7.5px 6px !important;
  }
  .new-entitity .select-auto .MuiFormLabel-root{
    line-height: 10px !important;
    font-size: 12px !important;
  }
  .new-entitity .select-auto .MuiFormLabel-root[data-shrink="true"]{
    display: none;
  }
  .new-entitity .MuiTextField-root .MuiOutlinedInput-notchedOutline{
    border: none !important;
  }
  .verific-class{
    color: #8686ad;
    overflow: hidden;
    white-space: nowrap;
  }
  .icon-blue{
    cursor: pointer;
  }
  .icon-blue svg{
    fill: blue !important;
    margin-left: 4px;
  }
  .completed-field{
    display: flex;
  }
  .completed-field img{
    height: 20px;
    margin-right: 4px;
  }
  .Export-entity{
    position: absolute !important;
    right: 255px;
    border: 1px solid #4646F2 !important;
    font-size: 13px !important;
    text-transform: capitalize !important;
    color: #4646F2 !important;
    padding: 7px !important;
    border-radius: 6px !important;
    height: 38px;
    width: 86px;
    bottom: 12px;
  }
  .MuiToolbar-root .Export-entity.MuiButton-sizeSmall[aria-label="Export"] svg{
    fill: #4646F2 !important;
    margin-top: 0px !important;
   }
   .MuiToolbar-root .Export-entity.MuiButton-sizeSmall[aria-label="Export"] span{
    margin-right: 2px!important;
    display: flex !important;
    align-items: center;
   }

  #progressbar {
    overflow: hidden;
    color: #000;
    padding-left: 0px;
    margin-top: 3vh;
    height:70px
}

#progressbar li {
    list-style-type: none;
    font-size: 0.8rem;
    width: 25%;
    float: left;
    position: relative;
    font-weight: 400;
    color: #000;
}

/* #progressbar #step1:before {
    content: "";
    color: #000;
    width: 20px;
    height: 20px;
    margin-left: 0px !important;
}

#progressbar #step2:before {
    content: "";
    color: #000;
    width: 20px;
    height: 20px;
    
}

#progressbar #step3:before {
    content: "";
    color: #000;
    width: 20px;
    height: 20px;
    margin-right: 32% ; 
}

#progressbar #step4:before {
    content: "";
    color: rgb(151, 149, 149, 0.651);
    width: 20px;
    height: 20px;
    margin-right: 0px !important;
} */

#progressbar .MuiButtonBase-root:before {
  content: "";
    color: #000;
    width: 12px;
    height: 12px;

    line-height: 29px;
    display: block;
    font-size: 12px;
    background: rgb(151, 149, 149);
    border-radius: 50%;
    z-index: -1;
    margin-bottom: 1vh;
    position: absolute;
    top: 36px;
}

#progressbar .MuiButtonBase-root:after {
    content: '';
    height: 3px;
    background: rgb(151, 149, 149, 0.651);
    position: absolute;
    left: 0%;
    right: 0%;
    margin-bottom: 2vh;
    top: 41px;
    z-index: 1;
}
.progress-track{
    padding: 0 8%;
}
#progressbar .MuiButtonBase-root{
  border-bottom: none !important;
}

#progressbar .MuiButtonBase-root.Mui-selected:before,
#progressbar .MuiButtonBase-root.Mui-selected:after {
    background: #4646F2 !important;
    opacity: 1 !important;
}

.new-entitity .entity-details .Mui-focused input, .new-entitity .entity-details input{
 height: 21.5px;
}
.entity-error-msg{
  margin-top: 12px;
}
.entity-error-msg ul{
  padding-left: 24px;
}

.entity-details p{
  font-size: 14px !important; 
  margin: 10px  0;
  white-space: nowrap;
}
.entity-details .MuiButton-root:hover {
  background: none !important;
  border: 1px solid #4646F2;
  color: #4646F2 !important;
}
.Addgroup:hover{
    background: none !important;
    border:1px solid #4646F2;
    color: #000 !important;
  }
.Addgroup .MuiSvgIcon-root{
  fill: #fff !important;
}
.Addgroup:hover .MuiSvgIcon-root{
  fill: #000 !important;
}

.AddNewModal .css-1wnsr1i{
    border: none !important;
    padding: 20px 30px !important;
    border-radius: 6px !important;
    width: 400px;
    }
    .AddNewModal #modal-modal-title{
    text-align: center !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    }
.AddNewModal h4{
    font-size: 15px !important;
    text-align: left !important;
    color: #323232 !important;
    }

    /* .AddNewModal .css-16f1609 .MuiFormLabel-root{
        margin-right: 20px;
        padding-top: 17px;
        line-height: 40px;
      } */
    .AddNewModal .rolename-top{
      padding-bottom: 12px;
    border-bottom: 1px solid #d8d8d8;
    }

    .AddNewModal input[type="text"],
    .AddNewModal .MuiSelect-root{
    background: #f0f0ff !important;
    padding: 8px 14px;
    border: none !important;
    border-radius: 5px !important;
    font-size: 14px;
    color: #000;
    }
    .AddNewModal .MuiFilledInput-root::before, .AddNewModal .MuiFilledInput-root::after,.AddNewModal .MuiFilledInput-root:hover::before{
      border-bottom: none !important;
    }
    .AddNewModal .MuiFormControl-root{
      margin: 0 !important;
      width: 100%;
    }
    .AddNewModal .MuiFilledInput-root {
      background-color: #f0f0ff!important;
      border: none!important;
      border-radius: 4px!important;
    }
    .AddNewModal .MuiTextField-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
    .AddNewModal .MuiTextField-root .css-1d3z3hw-MuiOutlinedInput-notchedOutline:hover {
    border: 1px solid #fffefe !important;
    }
    .AddNewModal .css-u4tvz2-MuiFormLabel-root{
    font-size:14px !important;
    }
    
    .AddNewModal .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    height: 38px !important;
    }
    
    .AddNewModal .MuiButton-root:first-child{
    background-color: #4646F2;
    float: right;
    border: 1px solid #4646F2;
    }
    .AddNewModal .MuiButton-root:first-child:hover{
      color: #4646F2 !important;
      background-color: #fff !important;
      border: 1px solid #4646F2 !important;
    }
    .AddNewModal .cancel-btn{
    background-color: #ffffff !important;
    border: 1px solid #4646F2 !important;
    color: #000 !important;
    float: right;
    }
   
    .Adduser:hover{
      width: 115px !important;
      padding: 5px 0 !important;
    }
    
    .Addrole-tabs .MuiTabs-flexContainer{
      flex-direction: column !important;
    }
    .AddNewModal .left-side{
      background: #ebebeb;
      padding-left: 0 !important;
    }
    .AddNewModal .left-side .css-2h8qje-MuiButtonBase-root-MuiTab-root.Mui-selected{
      background: #fff !important;
    }
    .AddNewModal .left-side .css-1aquho2-MuiTabs-indicator{
      background-color: transparent !important;
    }
    .Addrole-tabs .MuiTabs-flexContainer button{
      text-align: left !important;
      -webkit-align-items: flex-start;
        justify-content: flex-start;
    }
    .AddNewModal .css-mhc70k-MuiGrid-root>.MuiGrid-item {
      padding-top: 0px;
    }
    .AddNewModal .css-xnln8x{
      margin-top: 17px !important;
    }
    .AddNewModal .css-1aquho2-MuiTabs-indicator{
    background-color: transparent !important;
    }
    .AddNewModal .css-2h8qje-MuiButtonBase-root-MuiTab-root:hover:active::after,
    .AddNewModal .css-2h8qje-MuiButtonBase-root-MuiTab-root:focus::after{
      background-color: transparent !important;
    }

    input[type="file"] {
        display: none;
    }

    #file-drag {
        width: 38px;
        height: 38px;
        background: #E5E7EA 0% 0% no-repeat padding-box;
        border-radius: 10px;
        padding: 8px;
        font-size: 14px;
        color: #818C99;
        cursor: pointer;
        border: 2px dotted #818C99;
       margin-top: 15px;
    }
    #file-drag svg {
        font-size: 18px;
        fill: #4646F2;
        margin-left: 10px;
        margin-top: 8px;
    }
    .custom-Upload p{
        margin:2px 0;
        font-size:13px;
        color: #818C99;
    }
    .custom-Upload span{
        position: absolute;
        top: 80%;
        left: 55px;
        padding:10px;
    }
    .audit-left{
      width: 100%;
    }
    .audit-left h4{
        font-size: 14px;
        color: #000;
    }
    .audit-left input[type="date"]{
        height:32px !important;
        background:#f0f0ff;
        border: 1px solid #f0f0ff;
        border-radius: 5px;
        padding: 2px;
    }
    .audit-left .MuiSelect-select{
        padding: 7.51px 5px !important;
    }
    .audit-left .MuiFormLabel-root{
        font-size: 14px;
    }
    .audit-left .select-component .MuiFormControl-root .MuiOutlinedInput-root,.audit-left .select-component .MuiFormControl-root .MuiFilledInput-root {
      padding: 0 !important;
    }
    .audit-left .select-component .MuiFormLabel-root{
      margin-top: -2px;
    }
    .audit-left .select-component .MuiFormLabel-root[data-shrink="true"]{
      display: none;
    }
    .audit-left .select-component .css-319lph-ValueContainer{
      padding: 2px 0px !important;
    }
    .audit-left .select-component .MuiAutocomplete-root input{
      padding: 8.5px !important;
    }
    .Entitty-toolbar .uploadbtn {
      position: absolute !important;
      right: 157px!important;
      border: 1px solid #4646F2 !important;
      color: #4646F2 !important;
      background: #fff !important;
      text-transform: capitalize !important;
      border-radius: 6px !important;
      font-size: 13px;
      height: 38px;
      bottom: 12px;
  }
  .Entitty-toolbar .uploadbtn .MuiSvgIcon-root {
    fill: #4646F2 !important;
    width: 1rem !important;
    height: 1rem !important;
  }
    .Entitty-toolbar {
      margin-right: 4px;
      width: 60%;
    }
    .Entitty-toolbar h2{
      position: absolute;
      left: -8px;
      bottom: 10px;
    }
    .list-search input{
      padding: 8.5px 10px;
      margin-top: 3px;
      margin-bottom: 20px;
    }
    .list-search .MuiOutlinedInput-notchedOutline{
      border: none !important;
    }
    .list-search .search-btn{
      background: #4646F2;
      padding: 8px;
      border-radius: 6px;
      height: 36px;
      position: relative;
      top: 12px;
      margin-right: 23px;
      border: 1px solid #4646F2;
    }
    .list-search .search-btn:hover{
      background: #fff !important;
      color: #4646F2 !important;
      border: 1px solid #4646F2;
    }
    .list-search .search-btn:hover svg{
      fill: #4646F2 !important;
    }
    .list-search .search-btn svg{
      fill: #fff;
    }
    .audit-left input[type="date"]:focus{
      outline: none !important;
    }
    .group-toolbar{
      margin-right: 4px;
    }
    .Entity-page .show-btn{
      width: 76px;
      font-size: 12px !important;
      padding: 5px !important;
    }
    .Entity-page .vts-form{
      width: 100%;
    }
    .Entity-page .MuiAutocomplete-root .MuiInputBase-root {
      padding: 0 !important;
    }
    .Entity-page .Export-entity{
      right: 276px !important;
    }
    .Entity-page .refresh-btn-box {
      right: 366px !important;
    }
    .otp-successModal h2#modal-modal-title{
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      text-transform: uppercase;
    }
    .otp-successModal h4{
      text-align: center;
      font-size: 16px;
      font-weight: 600;
    }
    .otp-successModal p{
      text-align: center;
      font-size: 12px;
      margin-top: 6px;
    }
    .otp-successModal .modal-footer,.otp-successModal .modal-box{
      text-align: center;
    }
    .otp-successModal svg.warn-icon{
      border-radius: 18px;
      border: 2px solid red;
      fill: red;
      padding: 3px;
      margin-left: 18px;
      margin-bottom: 12px;
    }
    .otp-successModal svg.verified-icon{
      border-radius: 18px;
      border: 2px solid green;
      fill: green;
      padding: 3px;
      margin-left: 18px;
      margin-bottom: 12px;
    }
    .otp-successModal svg.mobile-icon{
      font-size: 26px;
      fill: #4646F2;
      border: 2px solid #0000ff87;
      padding: 4px;
      border-radius: 26px;
    }
    .otp-successModal .modal-footer .cancel-btn{
      background-color: #fff;
      color: #4646F2;
      box-shadow: none;
      padding: 5px 10px;
      border: 1px solid #4646F2;
      width: 120px;
      border-radius: 2px;
    }
    .otp-successModal .modal-footer .continue-btn{
      background-color: #4646F2;
      color: #fff;
      box-shadow: none;
      padding: 5px 10px;
      border: 1px solid #4646F2;
      width: 120px;
      border-radius: 2px;
    }
    .otp-successModal .modal-footer button:hover{
      background-color: #fff;
      color: #4646F2;
      border: 1px solid #4646F2;
    }
    /* verification popup */


      .otp-verification .form-otp {
          height: auto;
          padding-top: 10px;
          margin: 0 auto;
      }
      .otp-verification .form-otp .form-control {
          font-size: 16px;
          height: 40px;
          padding: 4px 6px 4px 6px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          border-radius: 0.15rem !important;
      }
      .otp-verification .form-otp .form-control:focus{
        outline: none !important;
      }
      .otp-verification .form-otp input[type="text"] {
        margin-bottom: 10px;
        background: #fff;
        margin-top: 8px;
        border: 1px solid #e1e1e1;
        text-align: center;
      }
  
      .otp-verification .form-otp input[type="password"] {
          background: grey;
      }
  
      .otp-verification  {
          padding: 15px 15px 5px 15px;
      }
  
      .resendOTP {
        font-size: 12px;
        color: #4646F2;
        font-weight: 600;
        text-align: center;
      }
  
      .form-control-placeholder {
          margin-bottom: 15px;
          text-align: center;
          font-size: 12px;
          color: #191919;
          font-weight: 600;
      }
      .Entity-page .sel-group .css-1s2u09g-control, .Entity-page .sel-group .css-1pahdxg-control, .Entity-page .sel-group .css-t3ipsp-control, .Entity-page .sel-group .css-13cymwt-control{
        width: 166px;
      }
    
      .tagfield{
        cursor: pointer;
      }
      .untagModal p{
        align-items: center;
      justify-content: center;
      text-align: center;
      display: flex;
      padding: 15px 36px;
      line-height: 22px;
      color: #67748E;
      font-weight: 600;
      font-size: 13px;
      }
      .untagModal .permit-detail{
        margin-top: 12px;
        text-align: center;
      }
      .untagModal .permit-detail h2{
        color: #252F40 !important;
        font-weight: 600 !important;
        font-size: 13px !important;
        margin: -18px;
      }
      .untagModal .permit-detail h2 span{
        color: #252F40 !important;
        font-weight: 400 !important;
        font-size: 14px !important;
      }
      .untagModal .modal-footer {
        text-align: center;
      }
      .untagModal .modal-footer .create-btn {
        background-color: #4646F2;
        border: 1px solid #4646F2;
        color: #FFFFFF !important;
        box-shadow: none !important;
      }
      .untagModal .modal-footer .cancel-btn{
        float: none !important;
        color: #4646F2 !important;
        box-shadow: none !important;
      }
  
      .otp-verifyModal .otp-verification .form-otp {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        /* width: 215px; */
    }
    .otp-verifyModal  .otp-verification .form-otp .form-control{
      width: 40px;
    }
    .otp-verifyModal  .otp-verification .resendOTP .fc-435367{
      color: #67748E;
      font-size: 13px;
    }
    .otp-verifyModal .otp-verification .resendOTP .fc-818c99{
      color: #63CE78;
      margin-left: 5px;
    }
    .otp-verifyModal  .modal-box{
      width: 364px !important;
    }
    .otp-successModal.otp-suc-tag p{
      color: #67748E;
      font-weight: 600;
      font-size: 13px;
    }
    .otp-successModal.otp-suc-tag .modal-footer .continue-btn{
      border: 1px solid #4646F2;
      border-radius: 4px;
    }
      @media only screen and (min-width: 992px) and (max-width:1043px) {
        .Entitty-toolbar button {
          top: 66px;
        }
        .Entitty-toolbar{
          width: 15%;
        }
        .MuiTablePagination-root .MuiToolbar-root{
          margin-top: 40px;
        }
      }

    @media (min-width: 2000px) {
        .new-entitity .modal-box {
          height: 636px;
        }
        .new-entitity .right-side .div-box {
          height: 410px;
        }
    }
    @media only screen and (min-width: 1044px) and (max-width:1068px) {
      .Entity-page .vts-form .search-btn{
        right: 214px;
      }
    }
    @media only screen and (min-width: 1069px) and (max-width:1100px) {
      .Entity-page .vts-form .search-btn{
        right: 226px;
      }
    }
    @media only screen and (min-width: 1101px) and (max-width:1140px) {
      .Entity-page .vts-form .search-btn{
        right: 262px;
      }
    }
    @media only screen and (min-width: 1141px) and (max-width:1200px) {
      .Entity-page .vts-form .search-btn{
        right: 290px;
      }
    }
    @media only screen and (min-width: 1201px) and (max-width:1247px) {
      .Entity-page .vts-form .search-btn{
        right: 320px;
      }
    }
    @media only screen and (min-width: 1248px) and (max-width:1276px) {
      .Entity-page .vts-form .search-btn{
        right: 345px;
      }
    }